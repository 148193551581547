<template>
    <div>
        <aven-financial-card-offer
            :on-displayed-callback="onDisplayedCallback"
            :handle-button-click="handleButtonClick"
            :handle-display-disclosures="toggleModal"
            :aven-upgrade-data="props.upgradeCardData"
            :personal-financial-liabilities-logos="props.personalFinancialLiabilitiesLogos"
            :pill-text="i18n.t('components.uccToHomeUpgradeCard.pillText')"
            :experiment-spec-group-name="getExperimentSpecGroupName()"
        />
        <modal-dialog
            :show="showDisclosuresModal"
            @close="toggleModal"
        >
            <p
                class="tw-text-xs tw-text-gray-400"
                v-html="modalContent"
            />
        </modal-dialog>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useOverviewStore } from '@/store/overviewStore'
    import { AvenUpgradeData, UpgradeCardType } from 'aven_types'
    import { logEvent } from '@/services/http-client'
    import AvenFinancialCardOffer from 'aven_shared/src/components/AvenFinancialCardOffer.vue'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { i18n } from '@/utils/i18n'
    import { EXPERIMENT_SPEC_GROUP_NAME, EXPERIMENT_TYPE, isExperimentEnabled } from '@/experiments/getOverrideForExperiment'
    import { OPEN_REPLAY_DEFAULT_FLUSH_TIMEOUT_MS, flushOpenReplayAndWindowOpen } from '@/utils/openReplay'

    const props =
        defineProps<{
            upgradeCardData: AvenUpgradeData
            personalFinancialLiabilitiesLogos?: (string | null)[]
        }>()

    const overviewStore = useOverviewStore()
    const upgradeCardType = computed(() => props.upgradeCardData.upgradeCardType)
    const otherLenderBalance = computed(() => props.upgradeCardData.monthlyPaymentInfo?.otherLenderBalance)
    const showDisclosuresModal = ref(false)
    const modalContent = computed(() => props.upgradeCardData.footnotes)

    const clickAnalyticsEventName = computed(() => {
        if (upgradeCardType.value === UpgradeCardType.personalLoanRefi) {
            return 'click_aven_my_ucc_to_home_pl_refi_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.creditCardBalanceTransfer) {
            return 'click_aven_my_ucc_to_home_credit_card_balance_transfer_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.genericAvenCash) {
            return 'click_aven_my_ucc_to_home_generic_aven_cash_upgrade_card'
        }
        return ''
    })

    const renderAnalyticsEventName = computed(() => {
        if (upgradeCardType.value === UpgradeCardType.personalLoanRefi) {
            return 'view_aven_my_ucc_to_home_pl_refi_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.creditCardBalanceTransfer) {
            return 'view_aven_my_ucc_to_home_credit_card_balance_transfer_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.genericAvenCash) {
            return 'view_aven_my_ucc_to_home_generic_aven_cash_upgrade_card'
        }
        return ''
    })

    const handleButtonClick = () => {
        logEvent(clickAnalyticsEventName.value, { experimentSpecGroupName: getExperimentSpecGroupName() ?? 'default' })
        const statedUsage = otherLenderBalance.value ?? 25_000
        const link = `${process.env.VUE_APP_AVEN_URL}/origination/asset_to_home/${overviewStore.returnToken}/${btoa(
            props.upgradeCardData.newUwPolicyName
        )}?cleanReload=true&utm_source=uccavenmy&utm_campaign=${upgradeCardType.value}&statedUsage=${statedUsage}`

        flushOpenReplayAndWindowOpen(link, OPEN_REPLAY_DEFAULT_FLUSH_TIMEOUT_MS)
    }

    const toggleModal = () => {
        showDisclosuresModal.value = !showDisclosuresModal.value
    }

    const onDisplayedCallback = () => {
        logEvent(renderAnalyticsEventName.value, { experimentSpecGroupName: getExperimentSpecGroupName() ?? 'default' })
    }

    const getExperimentSpecGroupName = () => {
        if (isExperimentEnabled(EXPERIMENT_TYPE.uccUpgrade, EXPERIMENT_SPEC_GROUP_NAME.ccbtMultiRowBtnVariant)) {
            return EXPERIMENT_SPEC_GROUP_NAME.ccbtMultiRowBtnVariant
        } else {
            return null
        }
    }
</script>

<style lang="scss" scoped></style>
