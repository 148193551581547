"use strict";
// Vesta is the loan origination system (LOS) provider for mortgage, providing us functionality
// out of the box that specifically helps us meet strict mortgage requirements.
//
// Version 25.1
// Express dates as YYYY-MM-DD
// https://aven.vesta.com/api?version=_25_2
// https://aven.beta.vesta.com/api?version=_25_2
Object.defineProperty(exports, "__esModule", { value: true });
exports.VestaCreditExternalProviderType = exports.VestaCreditPullRequestType = exports.VestaCreditPullType = exports.VestaCreditPullActionType = exports.VestaCompensationPaidByType = exports.VestaQuotePricingScenariosQueryType = exports.VestaMortgageSizeType = exports.VestaQuoteStatus = exports.VestaLoanAmortizationType = exports.VestaEconsentStatus = exports.VestaEconsentCaptureMethod = exports.VestaLiabilityAccountStatus = exports.VestaLiabilityIntent = exports.VestaLiabilityType = exports.VestaEmployerStatus = exports.VestaIncomeAmountType = exports.VestaIncomeType = exports.VestaAssetType = exports.VestaHmdaRaceDesignationTypePacificIslander = exports.VestaHmdaRaceDesignationType = exports.VestaHmdaRaceType = exports.VestaHmdaGenderType = exports.VestaHmdaEthnicityType = exports.VestaHmdaEthnicityOriginType = exports.VestaBankruptcyClosedType = exports.VestaBankruptcyChapterType = exports.VestaPriorPropertyTitleType = exports.VestaPriorPropertyUsageType = exports.VestaCitizenshipType = exports.VestaApplicationSubmitMethod = exports.VestaIncomeVerificationConsentType = exports.VestaCreditPullConsentType = exports.VestaMaritalStatus = exports.VestaPhoneNumberType = exports.VestaForm4506CTranscriptType = exports.VestaTaxReturnDocumentCode = exports.VestaTaxTranscriptSigningType = exports.VestaTaxIdentifierType = exports.VestaAddressStatus = exports.VestaAddressOccupancyType = exports.VestaPropertyValuationMethodType = exports.VestaPropertyUsageType = exports.VestaPropertyType = exports.VestaMortgageType = exports.VestaLienType = exports.VestaRefinanceCashOutDeterminationType = exports.VestaLoanType = exports.VestaLoanPurpose = exports.VestaEntityType = exports.VestaAction = void 0;
exports.VestaClosingAdjustmentCounterParty = exports.VestaClosingAdjustmentPaidToOrFromBorrower = exports.VestaClosingAdjustmentType = exports.VestaDisclosureRecipientType = exports.VestaDisclosureStatus = exports.VestaDisclosureProvider = exports.VestaDisclosurePackageType = void 0;
var VestaAction;
(function (VestaAction) {
    VestaAction["Add"] = "Add";
    VestaAction["Remove"] = "Remove";
})(VestaAction = exports.VestaAction || (exports.VestaAction = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/OntologyEntityType
var VestaEntityType;
(function (VestaEntityType) {
    VestaEntityType["Loan"] = "Loan";
    VestaEntityType["Borrower"] = "Borrower";
    VestaEntityType["Property"] = "Property";
    VestaEntityType["Asset"] = "Asset";
    VestaEntityType["Income"] = "Income";
    VestaEntityType["Liability"] = "Liability";
    VestaEntityType["BorrowerBusiness"] = "BorrowerBusiness";
    VestaEntityType["ConcurrentFinancing"] = "ConcurrentFinancing";
    VestaEntityType["Proration"] = "Proration";
    VestaEntityType["ClosingCost"] = "ClosingCost";
    VestaEntityType["CreditInquiry"] = "CreditInquiry";
    VestaEntityType["PublicRecord"] = "PublicRecord";
    VestaEntityType["UnderwritingException"] = "UnderwritingException";
    VestaEntityType["PropertyValuation"] = "PropertyValuation";
    VestaEntityType["Document"] = "Document";
    VestaEntityType["PricingRequest"] = "PricingRequest";
    VestaEntityType["TaxTranscriptRequestForm"] = "TaxTranscriptRequestForm";
})(VestaEntityType = exports.VestaEntityType || (exports.VestaEntityType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LoanPurpose
var VestaLoanPurpose;
(function (VestaLoanPurpose) {
    VestaLoanPurpose["Purchase"] = "Purchase";
    VestaLoanPurpose["Refinance"] = "Refinance";
})(VestaLoanPurpose = exports.VestaLoanPurpose || (exports.VestaLoanPurpose = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LoanType
var VestaLoanType;
(function (VestaLoanType) {
    VestaLoanType["Mortgage"] = "Mortgage";
    VestaLoanType["HELOC"] = "HELOC";
    VestaLoanType["HomeEquityLoan"] = "HomeEquityLoan";
})(VestaLoanType = exports.VestaLoanType || (exports.VestaLoanType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/RefinanceCashOutDeterminationType
var VestaRefinanceCashOutDeterminationType;
(function (VestaRefinanceCashOutDeterminationType) {
    VestaRefinanceCashOutDeterminationType["CashOut"] = "CashOut";
    VestaRefinanceCashOutDeterminationType["NoOrLimitedCashOut"] = "NoOrLimitedCashOut";
})(VestaRefinanceCashOutDeterminationType = exports.VestaRefinanceCashOutDeterminationType || (exports.VestaRefinanceCashOutDeterminationType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LienPriorityType
var VestaLienType;
(function (VestaLienType) {
    VestaLienType["FirstLien"] = "FirstLien";
    VestaLienType["SecondLien"] = "SecondLien";
    VestaLienType["ThirdLien"] = "ThirdLien";
    VestaLienType["FourthLien"] = "FourthLien";
})(VestaLienType = exports.VestaLienType || (exports.VestaLienType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/MortgageType
var VestaMortgageType;
(function (VestaMortgageType) {
    VestaMortgageType["Conventional"] = "Conventional";
    VestaMortgageType["FHA"] = "FHA";
    VestaMortgageType["USDA"] = "USDA";
    VestaMortgageType["VA"] = "VA";
})(VestaMortgageType = exports.VestaMortgageType || (exports.VestaMortgageType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/PropertyType
var VestaPropertyType;
(function (VestaPropertyType) {
    VestaPropertyType["SingleFamily"] = "SingleFamily";
    VestaPropertyType["Condominium"] = "Condominium";
    VestaPropertyType["Cooperative"] = "Cooperative";
    VestaPropertyType["TwoToFourUnitProperty"] = "TwoToFourUnitProperty";
    VestaPropertyType["ManufacturedHome"] = "ManufacturedHome";
    VestaPropertyType["PlannedUnitDevelopment"] = "PlannedUnitDevelopment";
    VestaPropertyType["Land"] = "Land";
    VestaPropertyType["MultiFamily"] = "MultiFamily";
})(VestaPropertyType = exports.VestaPropertyType || (exports.VestaPropertyType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/PropertyUsageType
var VestaPropertyUsageType;
(function (VestaPropertyUsageType) {
    VestaPropertyUsageType["PrimaryResidence"] = "PrimaryResidence";
    VestaPropertyUsageType["SecondHome"] = "SecondHome";
    VestaPropertyUsageType["Investment"] = "Investment";
    VestaPropertyUsageType["Other"] = "Other";
})(VestaPropertyUsageType = exports.VestaPropertyUsageType || (exports.VestaPropertyUsageType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/ValuationMethod
var VestaPropertyValuationMethodType;
(function (VestaPropertyValuationMethodType) {
    VestaPropertyValuationMethodType["FullAppraisal"] = "FullAppraisal";
    VestaPropertyValuationMethodType["PriorAppraisalUsed"] = "PriorAppraisalUsed";
    VestaPropertyValuationMethodType["DesktopAppraisal"] = "DesktopAppraisal";
    VestaPropertyValuationMethodType["AutomatedValuationModel"] = "AutomatedValuationModel";
    VestaPropertyValuationMethodType["BrokerPriceOpinion"] = "BrokerPriceOpinion";
    VestaPropertyValuationMethodType["DriveBy"] = "DriveBy";
    VestaPropertyValuationMethodType["TaxValuation"] = "TaxValuation";
    VestaPropertyValuationMethodType["Other"] = "Other";
    VestaPropertyValuationMethodType["None"] = "None";
    VestaPropertyValuationMethodType["DeskReview"] = "DeskReview";
    VestaPropertyValuationMethodType["FieldReview"] = "FieldReview";
    VestaPropertyValuationMethodType["HybridAppraisal"] = "HybridAppraisal";
})(VestaPropertyValuationMethodType = exports.VestaPropertyValuationMethodType || (exports.VestaPropertyValuationMethodType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/OccupancyType
var VestaAddressOccupancyType;
(function (VestaAddressOccupancyType) {
    VestaAddressOccupancyType["Own"] = "Own";
    VestaAddressOccupancyType["Rent"] = "Rent";
    VestaAddressOccupancyType["NoPrimaryHousingExpense"] = "NoPrimaryHousingExpense";
})(VestaAddressOccupancyType = exports.VestaAddressOccupancyType || (exports.VestaAddressOccupancyType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/AddressStatus
var VestaAddressStatus;
(function (VestaAddressStatus) {
    VestaAddressStatus["Current"] = "Current";
    VestaAddressStatus["Previous"] = "Previous";
})(VestaAddressStatus = exports.VestaAddressStatus || (exports.VestaAddressStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/TaxIdentifierType
var VestaTaxIdentifierType;
(function (VestaTaxIdentifierType) {
    VestaTaxIdentifierType["SocialSecurityNumber"] = "SocialSecurityNumber";
    VestaTaxIdentifierType["IndividualTaxpayerIdentificationNumber"] = "IndividualTaxpayerIdentificationNumber";
    // Vesta does not support EmployerIdentificationNumber
})(VestaTaxIdentifierType = exports.VestaTaxIdentifierType || (exports.VestaTaxIdentifierType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/TaxTranscriptSigningType
var VestaTaxTranscriptSigningType;
(function (VestaTaxTranscriptSigningType) {
    VestaTaxTranscriptSigningType["WetSigned"] = "WetSigned";
    VestaTaxTranscriptSigningType["ESigned"] = "ESigned";
})(VestaTaxTranscriptSigningType = exports.VestaTaxTranscriptSigningType || (exports.VestaTaxTranscriptSigningType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/TaxReturnDocumentCode
// Only W2 and 1040 are used right now
var VestaTaxReturnDocumentCode;
(function (VestaTaxReturnDocumentCode) {
    VestaTaxReturnDocumentCode["_1040"] = "_1040";
    VestaTaxReturnDocumentCode["_1065"] = "_1065";
    VestaTaxReturnDocumentCode["_1120"] = "_1120";
    VestaTaxReturnDocumentCode["W2"] = "W2";
    VestaTaxReturnDocumentCode["_1098"] = "_1098";
    VestaTaxReturnDocumentCode["_1099"] = "_1099";
    VestaTaxReturnDocumentCode["_5498"] = "_5498";
    VestaTaxReturnDocumentCode["_1120S"] = "_1120S";
})(VestaTaxReturnDocumentCode = exports.VestaTaxReturnDocumentCode || (exports.VestaTaxReturnDocumentCode = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/Form4506CTranscriptType
var VestaForm4506CTranscriptType;
(function (VestaForm4506CTranscriptType) {
    VestaForm4506CTranscriptType["ReturnTranscript"] = "ReturnTranscript";
    VestaForm4506CTranscriptType["AccountTranscript"] = "AccountTranscript";
    VestaForm4506CTranscriptType["RecordOfAccount"] = "RecordOfAccount";
})(VestaForm4506CTranscriptType = exports.VestaForm4506CTranscriptType || (exports.VestaForm4506CTranscriptType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/PhoneNumberType
var VestaPhoneNumberType;
(function (VestaPhoneNumberType) {
    VestaPhoneNumberType["Home"] = "Home";
    VestaPhoneNumberType["Work"] = "Work";
    VestaPhoneNumberType["Mobile"] = "Mobile";
    VestaPhoneNumberType["Other"] = "Other";
})(VestaPhoneNumberType = exports.VestaPhoneNumberType || (exports.VestaPhoneNumberType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/MaritalStatus
var VestaMaritalStatus;
(function (VestaMaritalStatus) {
    VestaMaritalStatus["Married"] = "Married";
    VestaMaritalStatus["Separated"] = "Separated";
    VestaMaritalStatus["Unmarried"] = "Unmarried";
})(VestaMaritalStatus = exports.VestaMaritalStatus || (exports.VestaMaritalStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CreditPullConsentType
var VestaCreditPullConsentType;
(function (VestaCreditPullConsentType) {
    VestaCreditPullConsentType["Verbal"] = "Verbal";
    VestaCreditPullConsentType["Written"] = "Written";
    VestaCreditPullConsentType["Electronic"] = "Electronic";
})(VestaCreditPullConsentType = exports.VestaCreditPullConsentType || (exports.VestaCreditPullConsentType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/IncomeVerificationConsentType
var VestaIncomeVerificationConsentType;
(function (VestaIncomeVerificationConsentType) {
    VestaIncomeVerificationConsentType["Verbal"] = "Verbal";
    VestaIncomeVerificationConsentType["Written"] = "Written";
    VestaIncomeVerificationConsentType["Electronic"] = "Electronic";
})(VestaIncomeVerificationConsentType = exports.VestaIncomeVerificationConsentType || (exports.VestaIncomeVerificationConsentType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/ApplicationSubmitMethod
var VestaApplicationSubmitMethod;
(function (VestaApplicationSubmitMethod) {
    VestaApplicationSubmitMethod["Email"] = "Email";
    VestaApplicationSubmitMethod["FaceToFace"] = "FaceToFace";
    VestaApplicationSubmitMethod["Fax"] = "Fax";
    VestaApplicationSubmitMethod["Internet"] = "Internet";
    VestaApplicationSubmitMethod["Mail"] = "Mail";
    VestaApplicationSubmitMethod["Telephone"] = "Telephone";
})(VestaApplicationSubmitMethod = exports.VestaApplicationSubmitMethod || (exports.VestaApplicationSubmitMethod = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CitizenshipType
var VestaCitizenshipType;
(function (VestaCitizenshipType) {
    VestaCitizenshipType["USCitizen"] = "USCitizen";
    VestaCitizenshipType["PermanentResidentAlien"] = "PermanentResidentAlien";
    VestaCitizenshipType["NonPermanentResidentAlien"] = "NonPermanentResidentAlien";
    VestaCitizenshipType["NonResidentAlien"] = "NonResidentAlien";
})(VestaCitizenshipType = exports.VestaCitizenshipType || (exports.VestaCitizenshipType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/PriorPropertyUsageType
var VestaPriorPropertyUsageType;
(function (VestaPriorPropertyUsageType) {
    VestaPriorPropertyUsageType["Investment"] = "Investment";
    VestaPriorPropertyUsageType["PrimaryResidence"] = "PrimaryResidence";
    VestaPriorPropertyUsageType["SecondHome"] = "SecondHome";
    VestaPriorPropertyUsageType["FHASecondaryResidence"] = "FHASecondaryResidence";
})(VestaPriorPropertyUsageType = exports.VestaPriorPropertyUsageType || (exports.VestaPriorPropertyUsageType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/PriorPropertyTitleType
var VestaPriorPropertyTitleType;
(function (VestaPriorPropertyTitleType) {
    VestaPriorPropertyTitleType["JointWithOtherThanSpouse"] = "JointWithOtherThanSpouse";
    VestaPriorPropertyTitleType["JointWithSpouse"] = "JointWithSpouse";
    VestaPriorPropertyTitleType["Sole"] = "Sole";
})(VestaPriorPropertyTitleType = exports.VestaPriorPropertyTitleType || (exports.VestaPriorPropertyTitleType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/BankruptcyChapterType
var VestaBankruptcyChapterType;
(function (VestaBankruptcyChapterType) {
    VestaBankruptcyChapterType["ChapterSeven"] = "ChapterSeven";
    VestaBankruptcyChapterType["ChapterEleven"] = "ChapterEleven";
    VestaBankruptcyChapterType["ChapterTwelve"] = "ChapterTwelve";
    VestaBankruptcyChapterType["ChapterThirteen"] = "ChapterThirteen";
})(VestaBankruptcyChapterType = exports.VestaBankruptcyChapterType || (exports.VestaBankruptcyChapterType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/BankruptcyClosedType
var VestaBankruptcyClosedType;
(function (VestaBankruptcyClosedType) {
    VestaBankruptcyClosedType["Discharged"] = "Discharged";
    VestaBankruptcyClosedType["Dismissed"] = "Dismissed";
})(VestaBankruptcyClosedType = exports.VestaBankruptcyClosedType || (exports.VestaBankruptcyClosedType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/HmdaEthnicityOriginType
var VestaHmdaEthnicityOriginType;
(function (VestaHmdaEthnicityOriginType) {
    VestaHmdaEthnicityOriginType["Mexican"] = "Mexican";
    VestaHmdaEthnicityOriginType["PuertoRican"] = "PuertoRican";
    VestaHmdaEthnicityOriginType["Cuban"] = "Cuban";
    VestaHmdaEthnicityOriginType["Other"] = "Other";
})(VestaHmdaEthnicityOriginType = exports.VestaHmdaEthnicityOriginType || (exports.VestaHmdaEthnicityOriginType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/HmdaEthnicityType
var VestaHmdaEthnicityType;
(function (VestaHmdaEthnicityType) {
    VestaHmdaEthnicityType["HispanicOrLatino"] = "HispanicOrLatino";
    VestaHmdaEthnicityType["NotHispanicOrLatino"] = "NotHispanicOrLatino";
    VestaHmdaEthnicityType["PreferNotToDisclose"] = "PreferNotToDisclose";
})(VestaHmdaEthnicityType = exports.VestaHmdaEthnicityType || (exports.VestaHmdaEthnicityType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/HMDAGenderType
var VestaHmdaGenderType;
(function (VestaHmdaGenderType) {
    VestaHmdaGenderType["Male"] = "Male";
    VestaHmdaGenderType["Female"] = "Female";
    VestaHmdaGenderType["PreferNotToDisclose"] = "PreferNotToDisclose";
})(VestaHmdaGenderType = exports.VestaHmdaGenderType || (exports.VestaHmdaGenderType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/HMDARaceType
var VestaHmdaRaceType;
(function (VestaHmdaRaceType) {
    VestaHmdaRaceType["AmericanIndianOrAlaskaNative"] = "AmericanIndianOrAlaskaNative";
    VestaHmdaRaceType["Asian"] = "Asian";
    VestaHmdaRaceType["BlackOrAfricanAmerican"] = "BlackOrAfricanAmerican";
    VestaHmdaRaceType["NativeHawaiianOrOtherPacificIslander"] = "NativeHawaiianOrOtherPacificIslander";
    VestaHmdaRaceType["White"] = "White";
    VestaHmdaRaceType["PreferNotToDisclose"] = "PreferNotToDisclose";
})(VestaHmdaRaceType = exports.VestaHmdaRaceType || (exports.VestaHmdaRaceType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/HMDARaceDesignationType
var VestaHmdaRaceDesignationType;
(function (VestaHmdaRaceDesignationType) {
    VestaHmdaRaceDesignationType["AsianIndian"] = "AsianIndian";
    VestaHmdaRaceDesignationType["Chinese"] = "Chinese";
    VestaHmdaRaceDesignationType["Filipino"] = "Filipino";
    VestaHmdaRaceDesignationType["Japanese"] = "Japanese";
    VestaHmdaRaceDesignationType["Korean"] = "Korean";
    VestaHmdaRaceDesignationType["OtherAsian"] = "OtherAsian";
    VestaHmdaRaceDesignationType["Vietnamese"] = "Vietnamese";
})(VestaHmdaRaceDesignationType = exports.VestaHmdaRaceDesignationType || (exports.VestaHmdaRaceDesignationType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/HMDARaceDesignationTypePacificIslander
var VestaHmdaRaceDesignationTypePacificIslander;
(function (VestaHmdaRaceDesignationTypePacificIslander) {
    VestaHmdaRaceDesignationTypePacificIslander["GuamanianOrChamorro"] = "GuamanianOrChamorro";
    VestaHmdaRaceDesignationTypePacificIslander["NativeHawaiian"] = "NativeHawaiian";
    VestaHmdaRaceDesignationTypePacificIslander["OtherPacificIslander"] = "OtherPacificIslander";
    VestaHmdaRaceDesignationTypePacificIslander["Samoan"] = "Samoan";
})(VestaHmdaRaceDesignationTypePacificIslander = exports.VestaHmdaRaceDesignationTypePacificIslander || (exports.VestaHmdaRaceDesignationTypePacificIslander = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/AssetType
var VestaAssetType;
(function (VestaAssetType) {
    VestaAssetType["Bond"] = "Bond";
    VestaAssetType["BridgeLoanNotDeposited"] = "BridgeLoanNotDeposited";
    VestaAssetType["CertificateOfDepositTimeDeposit"] = "CertificateOfDepositTimeDeposit";
    VestaAssetType["CheckingAccount"] = "CheckingAccount";
    VestaAssetType["IndividualDevelopmentAccount"] = "IndividualDevelopmentAccount";
    VestaAssetType["LifeInsurance"] = "LifeInsurance";
    VestaAssetType["MoneyMarketFund"] = "MoneyMarketFund";
    VestaAssetType["MutualFund"] = "MutualFund";
    VestaAssetType["RetirementFund"] = "RetirementFund";
    VestaAssetType["SavingsAccount"] = "SavingsAccount";
    VestaAssetType["Stock"] = "Stock";
    VestaAssetType["StockOptions"] = "StockOptions";
    VestaAssetType["TrustAccount"] = "TrustAccount";
    VestaAssetType["CashOnHand"] = "CashOnHand";
    VestaAssetType["Other"] = "Other";
    VestaAssetType["PendingNetSaleProceedsFromRealEstateAssets"] = "PendingNetSaleProceedsFromRealEstateAssets";
    VestaAssetType["ProceedsFromSaleOfNonRealEstateAsset"] = "ProceedsFromSaleOfNonRealEstateAsset";
    VestaAssetType["ProceedsFromSecuredLoan"] = "ProceedsFromSecuredLoan";
    VestaAssetType["ProceedsFromUnsecuredLoan"] = "ProceedsFromUnsecuredLoan";
    VestaAssetType["GiftOfCash"] = "GiftOfCash";
    VestaAssetType["Grant"] = "Grant";
})(VestaAssetType = exports.VestaAssetType || (exports.VestaAssetType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/IncomeType
var VestaIncomeType;
(function (VestaIncomeType) {
    VestaIncomeType["AccessoryUnit"] = "AccessoryUnit";
    VestaIncomeType["Alimony"] = "Alimony";
    VestaIncomeType["AutomobileAllowance"] = "AutomobileAllowance";
    VestaIncomeType["Boarder"] = "Boarder";
    VestaIncomeType["CapitalGains"] = "CapitalGains";
    VestaIncomeType["ChildSupport"] = "ChildSupport";
    VestaIncomeType["DividendsInterest"] = "DividendsInterest";
    VestaIncomeType["Employment"] = "Employment";
    VestaIncomeType["FosterCare"] = "FosterCare";
    VestaIncomeType["HousingAllowance"] = "HousingAllowance";
    VestaIncomeType["HousingChoiceVoucherProgram"] = "HousingChoiceVoucherProgram";
    VestaIncomeType["MortgageCreditCertificate"] = "MortgageCreditCertificate";
    VestaIncomeType["MortgageDifferential"] = "MortgageDifferential";
    VestaIncomeType["NotesReceivableInstallment"] = "NotesReceivableInstallment";
    VestaIncomeType["Other"] = "Other";
    VestaIncomeType["Pension"] = "Pension";
    VestaIncomeType["PublicAssistance"] = "PublicAssistance";
    VestaIncomeType["Rental"] = "Rental";
    VestaIncomeType["Retirement"] = "Retirement";
    VestaIncomeType["Royalties"] = "Royalties";
    VestaIncomeType["SelfEmployment"] = "SelfEmployment";
    VestaIncomeType["SeparateMaintenance"] = "SeparateMaintenance";
    VestaIncomeType["SocialSecurity"] = "SocialSecurity";
    VestaIncomeType["TemporaryLeave"] = "TemporaryLeave";
    VestaIncomeType["Trust"] = "Trust";
    VestaIncomeType["UnemploymentBenefits"] = "UnemploymentBenefits";
    VestaIncomeType["VABenefitsNonEducational"] = "VABenefitsNonEducational";
    VestaIncomeType["Disability"] = "Disability";
})(VestaIncomeType = exports.VestaIncomeType || (exports.VestaIncomeType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/IncomeAmountType
var VestaIncomeAmountType;
(function (VestaIncomeAmountType) {
    VestaIncomeAmountType["Weekly"] = "Weekly";
    VestaIncomeAmountType["BiWeekly"] = "BiWeekly";
    VestaIncomeAmountType["SemiMonthly"] = "SemiMonthly";
    VestaIncomeAmountType["Monthly"] = "Monthly";
    VestaIncomeAmountType["Quarterly"] = "Quarterly";
    VestaIncomeAmountType["SemiYearly"] = "SemiYearly";
    VestaIncomeAmountType["Annual"] = "Annual";
    VestaIncomeAmountType["AsOf"] = "AsOf";
    VestaIncomeAmountType["Hourly"] = "Hourly";
})(VestaIncomeAmountType = exports.VestaIncomeAmountType || (exports.VestaIncomeAmountType = {}));
var VestaEmployerStatus;
(function (VestaEmployerStatus) {
    VestaEmployerStatus["Current"] = "Current";
    VestaEmployerStatus["Previous"] = "Previous";
})(VestaEmployerStatus = exports.VestaEmployerStatus || (exports.VestaEmployerStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LiabilityType
/**  @TranslateToPython @TranslateToEnum  */
var VestaLiabilityType;
(function (VestaLiabilityType) {
    VestaLiabilityType["CollectionsJudgmentsAndLiens"] = "CollectionsJudgmentsAndLiens";
    VestaLiabilityType["UnsecuredInstallment"] = "UnsecuredInstallment";
    VestaLiabilityType["SecuredInstallment"] = "SecuredInstallment";
    VestaLiabilityType["LeasePayment"] = "LeasePayment";
    VestaLiabilityType["Open30DayChargeAccount"] = "Open30DayChargeAccount";
    VestaLiabilityType["RevolvingCreditLine"] = "RevolvingCreditLine";
    VestaLiabilityType["Taxes"] = "Taxes";
    VestaLiabilityType["TaxLien"] = "TaxLien";
    VestaLiabilityType["HELOC"] = "HELOC";
    VestaLiabilityType["HomeEquityLoan"] = "HomeEquityLoan";
    VestaLiabilityType["PACELoan"] = "PACELoan";
    VestaLiabilityType["Mortgage"] = "Mortgage";
    VestaLiabilityType["OtherLiability"] = "OtherLiability";
    VestaLiabilityType["Alimony"] = "Alimony";
    VestaLiabilityType["ChildSupport"] = "ChildSupport";
    VestaLiabilityType["JobRelatedExpenses"] = "JobRelatedExpenses";
    VestaLiabilityType["SeparateMaintenanceExpense"] = "SeparateMaintenanceExpense";
    VestaLiabilityType["OtherExpense"] = "OtherExpense";
    VestaLiabilityType["EquityBuyout"] = "EquityBuyout";
    VestaLiabilityType["DeferredStudentLoan"] = "DeferredStudentLoan";
})(VestaLiabilityType = exports.VestaLiabilityType || (exports.VestaLiabilityType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LiabilityIntent
var VestaLiabilityIntent;
(function (VestaLiabilityIntent) {
    VestaLiabilityIntent["DoNothing"] = "DoNothing";
    VestaLiabilityIntent["PayAtClosing"] = "PayAtClosing";
    VestaLiabilityIntent["PayBeforeClosing"] = "PayBeforeClosing";
    VestaLiabilityIntent["Subordinate"] = "Subordinate";
    VestaLiabilityIntent["ExcludeLessThan10MonthsLeft"] = "ExcludeLessThan10MonthsLeft";
    VestaLiabilityIntent["ExcludePaidByOthers"] = "ExcludePaidByOthers";
    VestaLiabilityIntent["ExcludeAssignedToAnotherParty"] = "ExcludeAssignedToAnotherParty";
    VestaLiabilityIntent["ExcludeAssociatedPropertySold"] = "ExcludeAssociatedPropertySold";
    VestaLiabilityIntent["ExcludeBusinessDebtPaidFromBusinessFunds"] = "ExcludeBusinessDebtPaidFromBusinessFunds";
    VestaLiabilityIntent["ExcludeDebtIsAssetCollateralized"] = "ExcludeDebtIsAssetCollateralized";
    VestaLiabilityIntent["ExcludeNonApplicantDebt"] = "ExcludeNonApplicantDebt";
    VestaLiabilityIntent["ExcludeRentalPaymentFromPreviousResidence"] = "ExcludeRentalPaymentFromPreviousResidence";
    VestaLiabilityIntent["ExcludeOther"] = "ExcludeOther";
    VestaLiabilityIntent["Exclude30DayChargeAccount"] = "Exclude30DayChargeAccount";
    VestaLiabilityIntent["ExcludeUtilities"] = "ExcludeUtilities";
})(VestaLiabilityIntent = exports.VestaLiabilityIntent || (exports.VestaLiabilityIntent = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LiabilityAccountStatus
var VestaLiabilityAccountStatus;
(function (VestaLiabilityAccountStatus) {
    VestaLiabilityAccountStatus["Closed"] = "Closed";
    VestaLiabilityAccountStatus["Frozen"] = "Frozen";
    VestaLiabilityAccountStatus["Open"] = "Open";
    VestaLiabilityAccountStatus["Paid"] = "Paid";
    VestaLiabilityAccountStatus["Refinanced"] = "Refinanced";
    VestaLiabilityAccountStatus["Transferred"] = "Transferred";
})(VestaLiabilityAccountStatus = exports.VestaLiabilityAccountStatus || (exports.VestaLiabilityAccountStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/EconsentCaptureMethod
var VestaEconsentCaptureMethod;
(function (VestaEconsentCaptureMethod) {
    VestaEconsentCaptureMethod["Electronic"] = "Electronic";
    VestaEconsentCaptureMethod["Verbal"] = "Verbal";
})(VestaEconsentCaptureMethod = exports.VestaEconsentCaptureMethod || (exports.VestaEconsentCaptureMethod = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/EconsentStatus
var VestaEconsentStatus;
(function (VestaEconsentStatus) {
    VestaEconsentStatus["Accept"] = "Accept";
    VestaEconsentStatus["Decline"] = "Decline";
    VestaEconsentStatus["Withdrawn"] = "Withdrawn";
})(VestaEconsentStatus = exports.VestaEconsentStatus || (exports.VestaEconsentStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/LoanAmortizationType
var VestaLoanAmortizationType;
(function (VestaLoanAmortizationType) {
    VestaLoanAmortizationType["FixedRateMortgage"] = "FixedRateMortgage";
    VestaLoanAmortizationType["AdjustableRateMortgage"] = "AdjustableRateMortgage";
    VestaLoanAmortizationType["GrowingEquityMortgage"] = "GrowingEquityMortgage";
    VestaLoanAmortizationType["GraduatedPaymentMortgage"] = "GraduatedPaymentMortgage";
    VestaLoanAmortizationType["StepRateMortgage"] = "StepRateMortgage";
    VestaLoanAmortizationType["Other"] = "Other";
})(VestaLoanAmortizationType = exports.VestaLoanAmortizationType || (exports.VestaLoanAmortizationType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/QuoteStatus
var VestaQuoteStatus;
(function (VestaQuoteStatus) {
    VestaQuoteStatus["Active"] = "Active";
    VestaQuoteStatus["UpdateInProgress"] = "UpdateInProgress";
    VestaQuoteStatus["Expired"] = "Expired";
    VestaQuoteStatus["Suspended"] = "Suspended";
})(VestaQuoteStatus = exports.VestaQuoteStatus || (exports.VestaQuoteStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/MortgageSizeType
var VestaMortgageSizeType;
(function (VestaMortgageSizeType) {
    VestaMortgageSizeType["Conforming"] = "Conforming";
    VestaMortgageSizeType["HighBalance"] = "HighBalance";
    VestaMortgageSizeType["Jumbo"] = "Jumbo";
})(VestaMortgageSizeType = exports.VestaMortgageSizeType || (exports.VestaMortgageSizeType = {}));
var VestaQuotePricingScenariosQueryType;
(function (VestaQuotePricingScenariosQueryType) {
    VestaQuotePricingScenariosQueryType["InitialQuery"] = "InitialQuery";
    VestaQuotePricingScenariosQueryType["Reprice"] = "Reprice";
    VestaQuotePricingScenariosQueryType["RequestReprice"] = "RequestReprice";
    VestaQuotePricingScenariosQueryType["RequestRelock"] = "RequestRelock";
    VestaQuotePricingScenariosQueryType["HistoricalPricing"] = "HistoricalPricing";
    VestaQuotePricingScenariosQueryType["Relock"] = "Relock";
})(VestaQuotePricingScenariosQueryType = exports.VestaQuotePricingScenariosQueryType || (exports.VestaQuotePricingScenariosQueryType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CompensationPaidByType
var VestaCompensationPaidByType;
(function (VestaCompensationPaidByType) {
    VestaCompensationPaidByType["Borrower"] = "Borrower";
    VestaCompensationPaidByType["Lender"] = "Lender";
})(VestaCompensationPaidByType = exports.VestaCompensationPaidByType || (exports.VestaCompensationPaidByType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CreditPullActionType
var VestaCreditPullActionType;
(function (VestaCreditPullActionType) {
    VestaCreditPullActionType["PullNew"] = "PullNew";
    VestaCreditPullActionType["Upgrade"] = "Upgrade";
    VestaCreditPullActionType["Retrieve"] = "Retrieve";
})(VestaCreditPullActionType = exports.VestaCreditPullActionType || (exports.VestaCreditPullActionType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CreditPullType
var VestaCreditPullType;
(function (VestaCreditPullType) {
    VestaCreditPullType["Hard"] = "Hard";
    VestaCreditPullType["Soft"] = "Soft";
})(VestaCreditPullType = exports.VestaCreditPullType || (exports.VestaCreditPullType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CreditPullRequestType
var VestaCreditPullRequestType;
(function (VestaCreditPullRequestType) {
    VestaCreditPullRequestType["Individual"] = "Individual";
    VestaCreditPullRequestType["Joint"] = "Joint";
})(VestaCreditPullRequestType = exports.VestaCreditPullRequestType || (exports.VestaCreditPullRequestType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/CreditExternalProviderType
// We only support CredCo for now
var VestaCreditExternalProviderType;
(function (VestaCreditExternalProviderType) {
    VestaCreditExternalProviderType["CredCo"] = "CredCo";
})(VestaCreditExternalProviderType = exports.VestaCreditExternalProviderType || (exports.VestaCreditExternalProviderType = {}));
// We define this ourselves, but the standard package types are InitialDisclosure, Redisclosure, ClosingDisclosure, and Closing
// As of 2025-02-24, we only support InitialDisclosure
var VestaDisclosurePackageType;
(function (VestaDisclosurePackageType) {
    VestaDisclosurePackageType["InitialDisclosure"] = "InitialDisclosure";
})(VestaDisclosurePackageType = exports.VestaDisclosurePackageType || (exports.VestaDisclosurePackageType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/DisclosureProvider
var VestaDisclosureProvider;
(function (VestaDisclosureProvider) {
    VestaDisclosureProvider["Docutech"] = "Docutech";
})(VestaDisclosureProvider = exports.VestaDisclosureProvider || (exports.VestaDisclosureProvider = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/DisclosureStatus
var VestaDisclosureStatus;
(function (VestaDisclosureStatus) {
    VestaDisclosureStatus["Sent"] = "Sent";
    VestaDisclosureStatus["Completed"] = "Completed";
    VestaDisclosureStatus["Cancelled"] = "Cancelled";
    VestaDisclosureStatus["Failed"] = "Failed";
    VestaDisclosureStatus["Sending"] = "Sending";
    VestaDisclosureStatus["Expired"] = "Expired";
})(VestaDisclosureStatus = exports.VestaDisclosureStatus || (exports.VestaDisclosureStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/DisclosureRecipientType
var VestaDisclosureRecipientType;
(function (VestaDisclosureRecipientType) {
    VestaDisclosureRecipientType["Borrower"] = "Borrower";
    VestaDisclosureRecipientType["Lender"] = "Lender";
    VestaDisclosureRecipientType["ThirdParty"] = "ThirdParty";
})(VestaDisclosureRecipientType = exports.VestaDisclosureRecipientType || (exports.VestaDisclosureRecipientType = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/ClosingAdjustmentType
var VestaClosingAdjustmentType;
(function (VestaClosingAdjustmentType) {
    VestaClosingAdjustmentType["FuelCosts"] = "FuelCosts";
    VestaClosingAdjustmentType["LenderCredit"] = "LenderCredit";
    VestaClosingAdjustmentType["RentFromSubjectProperty"] = "RentFromSubjectProperty";
    VestaClosingAdjustmentType["RepairCompletionEscrowHoldback"] = "RepairCompletionEscrowHoldback";
    VestaClosingAdjustmentType["Repairs"] = "Repairs";
    VestaClosingAdjustmentType["SatisfactionOfSubordinateLien"] = "SatisfactionOfSubordinateLien";
    VestaClosingAdjustmentType["SellersEscrowAssumption"] = "SellersEscrowAssumption";
    VestaClosingAdjustmentType["SellersMortgageInsuranceAssumption"] = "SellersMortgageInsuranceAssumption";
    VestaClosingAdjustmentType["SellersReserveAccountAssumption"] = "SellersReserveAccountAssumption";
    VestaClosingAdjustmentType["Services"] = "Services";
    VestaClosingAdjustmentType["TenantSecurityDeposit"] = "TenantSecurityDeposit";
    VestaClosingAdjustmentType["UnpaidUtilityEscrowHoldback"] = "UnpaidUtilityEscrowHoldback";
    VestaClosingAdjustmentType["Other"] = "Other";
    VestaClosingAdjustmentType["RebateCredit"] = "RebateCredit";
    VestaClosingAdjustmentType["SubordinateFinancingProceeds"] = "SubordinateFinancingProceeds";
    VestaClosingAdjustmentType["LenderCreditToleranceCures"] = "LenderCreditToleranceCures";
    VestaClosingAdjustmentType["LenderCreditPoints"] = "LenderCreditPoints";
    VestaClosingAdjustmentType["AdditionalSaleOfSellerProperty"] = "AdditionalSaleOfSellerProperty";
    VestaClosingAdjustmentType["GiftOfEquity"] = "GiftOfEquity";
    VestaClosingAdjustmentType["RelocationFunds"] = "RelocationFunds";
    VestaClosingAdjustmentType["SweatEquity"] = "SweatEquity";
    VestaClosingAdjustmentType["TradeEquity"] = "TradeEquity";
    VestaClosingAdjustmentType["ManagedLenderCredit"] = "ManagedLenderCredit";
    VestaClosingAdjustmentType["LenderCreditConcession"] = "LenderCreditConcession";
    VestaClosingAdjustmentType["EmployerAssistedHousing"] = "EmployerAssistedHousing";
    VestaClosingAdjustmentType["LeasePurchaseFund"] = "LeasePurchaseFund";
    VestaClosingAdjustmentType["LotEquity"] = "LotEquity";
})(VestaClosingAdjustmentType = exports.VestaClosingAdjustmentType || (exports.VestaClosingAdjustmentType = {}));
var VestaClosingAdjustmentPaidToOrFromBorrower;
(function (VestaClosingAdjustmentPaidToOrFromBorrower) {
    VestaClosingAdjustmentPaidToOrFromBorrower["ToBorrower"] = "ToBorrower";
    VestaClosingAdjustmentPaidToOrFromBorrower["FromBorrower"] = "FromBorrower";
})(VestaClosingAdjustmentPaidToOrFromBorrower = exports.VestaClosingAdjustmentPaidToOrFromBorrower || (exports.VestaClosingAdjustmentPaidToOrFromBorrower = {}));
// https://aven.beta.vesta.com/api?version=_25_2#/schemas/ClosingAdjustmentCounterparty
var VestaClosingAdjustmentCounterParty;
(function (VestaClosingAdjustmentCounterParty) {
    VestaClosingAdjustmentCounterParty["Seller"] = "Seller";
    VestaClosingAdjustmentCounterParty["Lender"] = "Lender";
    VestaClosingAdjustmentCounterParty["Builder"] = "Builder";
    VestaClosingAdjustmentCounterParty["Broker"] = "Broker";
    VestaClosingAdjustmentCounterParty["RealEstateAgent"] = "RealEstateAgent";
    VestaClosingAdjustmentCounterParty["Correspondent"] = "Correspondent";
    VestaClosingAdjustmentCounterParty["Other"] = "Other";
    VestaClosingAdjustmentCounterParty["Employer"] = "Employer";
    VestaClosingAdjustmentCounterParty["BuilderDeveloper"] = "BuilderDeveloper";
    VestaClosingAdjustmentCounterParty["EmployerAffiliate"] = "EmployerAffiliate";
    VestaClosingAdjustmentCounterParty["LenderAffiliate"] = "LenderAffiliate";
    VestaClosingAdjustmentCounterParty["OtherInterestedParty"] = "OtherInterestedParty";
})(VestaClosingAdjustmentCounterParty = exports.VestaClosingAdjustmentCounterParty || (exports.VestaClosingAdjustmentCounterParty = {}));
