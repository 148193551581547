import OpenReplayTracker from '@openreplay/tracker'
import OpenReplayAxiosTracker from '@openreplay/tracker-axios'
import { InputMode } from '@openreplay/tracker/lib/modules/input'
import { inspect, Logger } from '../utils/logger'
import { AxiosInstance } from 'axios'

// Create a logger instance for this module
const logger = new Logger({
    avenProject: 'aven-shared',
    sentryDSN: process.env.VUE_APP_SENTRY_DSN || '',
    clientSideLogsEnabled: process.env.VUE_APP_NODE_ENV !== 'production',
    getMetadata: () => ({
        service: 'openReplay',
    }),
})

// Add type declaration for the window object to include httpClient
declare global {
    interface Window {
        httpClient?: any
    }
}

// For compatibility with all Aven applications that use different implementations of httpClient
interface HttpClient {
    post(url: string, data?: any, config?: any): Promise<any>
}

/**
 * Unified OpenReplay class that combines functionality from all Aven applications that supports:
 * - HTTP request tracking
 * - Session recording
 * - Metadata tracking
 */
export class OpenReplay {
    public openReplayTracker: OpenReplayTracker | undefined
    private sessionId: string | undefined

    constructor(private readonly projectKey: string | undefined) {
        try {
            if (this.projectKey) {
                this.openReplayTracker = new OpenReplayTracker({
                    projectKey: this.projectKey,
                    ingestPoint: 'https://openreplay.aven.com/ingest',
                    captureIFrames: false, // don't capture very sensitive info
                    obscureInputEmails: false,
                    obscureInputNumbers: false,
                    obscureInputDates: false,
                    obscureTextEmails: false,
                    obscureTextNumbers: false,
                    defaultInputMode: InputMode.Plain,
                })
            } else {
                logger.info(`Skipping OpenReplayTracker instantiation on ${process.env.VUE_APP_NODE_ENV} env`)
            }
        } catch (error) {
            logger.error(`OpenReplayTracker failed to instantiate`, error)
        }
    }

    /**
     * Initialize OpenReplay for a session
     * @param sessionId - The unique session identifier
     * @param userTraits - User metadata to associate with the session
     * @param trackedHttpClients - Array of HTTP clients to track (must have initializeOpenReplayRequestTracker method)
     * @param urlSubmitHttpClient - HTTP client to submit the session recording URL
     * @returns The session recording URL if available, undefined otherwise
     */
    public init = async (
        sessionId: string,
        userTraits: Record<string, any>,
        trackedHttpClients: Array<{ initializeOpenReplayRequestTracker: (tracker: OpenReplayTracker) => void }> = [],
        urlSubmitHttpClient: HttpClient
    ): Promise<string | undefined> => {
        // Check if already initialized with this session ID
        if (this.openReplayTracker && this.openReplayTracker.isActive() && this.sessionId === sessionId) {
            logger.info(`Tried to initialize openReplay twice in the same session, exiting instead`)
            return undefined
        }

        this.sessionId = sessionId
        logger.info(`OpenReplay with sessionId: ${sessionId}`)

        try {
            let sessionRecordingUrl: string | undefined

            if (this.projectKey && this.openReplayTracker) {
                // Set up HTTP tracking for all provided clients
                if (trackedHttpClients.length > 0) {
                    logger.info(`Adding request watchers for openreplay`)
                    trackedHttpClients.forEach((client) => {
                        client.initializeOpenReplayRequestTracker(this.openReplayTracker!)
                    })
                } else {
                    // If no HTTP clients provided, we can still track axios instances directly
                    // This is kept for backward compatibility with the aven_my implementation
                    // Note: httpClient is expected to be provided by the application using this service
                    if (window.httpClient) {
                        this.openReplayTracker.use(
                            OpenReplayAxiosTracker({
                                instance: window.httpClient,
                                ignoreHeaders: ['sessionauthorization'],
                            })
                        )
                    }
                }

                // Start the tracker with user information
                await this.openReplayTracker.start({
                    userID: sessionId,
                    sessionHash: sessionId, // enables cross domain linking of sessions
                    metadata: userTraits,
                })

                logger.info(`OpenReplay started`)
                logger.info(`Attempting to get openreplay sessionRecordingUrl for sessionID ${sessionId}`)
                sessionRecordingUrl = this.openReplayTracker.getSessionURL()

                // Submit the session recording URL to the backend
                await urlSubmitHttpClient.post('/ana/sessionRecordingUrl', { sessionId, sessionRecordingUrl })
            } else {
                logger.info(`Skipping OpenReplayTracker initialization on ${process.env.VUE_APP_NODE_ENV} env`)
                sessionRecordingUrl = 'local dev test openreplay session url'
            }

            // Each application will handle storage of the session recording URL in their own way
            return sessionRecordingUrl
        } catch (error) {
            logger.error(`OpenReplayTracker failed to initialize for sessionId: ${sessionId}.`, error)
            return undefined
        }
    }

    /**
     * Set metadata on the OpenReplay tracker
     * @param metadata - Key-value pairs to set as metadata
     * @param sessionIdParam - Optional session ID (uses the current session ID if not provided)
     * @returns boolean indicating success
     */
    public trySetMetadata = (metadata: Record<string, any>, sessionIdParam?: string): boolean => {
        // If a specific session ID is provided, check if it matches the current session
        if (sessionIdParam && this.sessionId !== sessionIdParam) {
            logger.error(`Tried to set metadata for a different session, exiting instead`)
            return false
        }

        if (!this.openReplayTracker) {
            logger.error(`Tried to set metadata with uninitialized openreplay exiting instead`)
            return false
        }

        try {
            logger.info(`Trying to set metadata on openreplay: ${inspect(metadata)}`)
            for (const [trait, value] of Object.entries(metadata)) {
                this.openReplayTracker.setMetadata(trait, value)
            }
            return true
        } catch (error) {
            logger.error(`failed to setMetadata on openreplay: ${inspect(metadata)}.`, error)
            return false
        }
    }

    /**
     * Force flush the batch on openreplay - this helps prevent data loss in the event of tab or iframe close
     * @param timeoutMs - Optional timeout in milliseconds. If not provided, will wait indefinitely for the flush to complete
     * @returns Promise that resolves to true if successful, false otherwise
     */
    public forceFlushBatch = (timeoutMs?: number): Promise<boolean> => {
        return new Promise((resolve) => {
            if (!this.openReplayTracker) {
                logger.error(`Tried to flush batch with uninitialized openreplay exiting instead`)
                resolve(false)
                return
            }
            try {
                logger.info(`Trying to flush batch on openreplay`)
                let timeout: ReturnType<typeof setTimeout> | undefined

                if (timeoutMs !== undefined) {
                    timeout = setTimeout(() => {
                        logger.info(`forceFlushBatch timed out after ${timeoutMs}ms, resolving as false`)
                        resolve(false)
                    }, timeoutMs)
                }

                this.openReplayTracker.forceFlushBatch((success) => {
                    if (timeout) {
                        clearTimeout(timeout)
                    }
                    if (success) {
                        logger.info(`forceFlushBatch completed on openreplay`)
                        resolve(true)
                    } else {
                        logger.error(`forceFlushBatch failed on openreplay`)
                        resolve(false)
                    }
                })
            } catch (error) {
                logger.error(`failed to forceFlushBatch on openreplay.`, error)
                resolve(false)
            }
        })
    }
}
