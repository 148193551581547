"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IrsFormVerificationStatus = exports.IncomeVerificationDocumentPendingReason = exports.PaystubProvider = exports.ManualIncomeVerificationResult = exports.IncomeVerificationPurpose = exports.IncomeVerificationResultType = exports.UnsuccessfulPlaidOwnerNameMatchStatus = exports.IncomeVerificationDocumentAdditionalInfoKey = exports.ManualIncomeVerificationSource = exports.IncomeVerificationDocumentType = void 0;
/** @TranslateToPython @TranslateToEnum */
var IncomeVerificationDocumentType;
(function (IncomeVerificationDocumentType) {
    IncomeVerificationDocumentType["PAY_STUBS"] = "PAY_STUBS";
    IncomeVerificationDocumentType["W2"] = "W2";
    IncomeVerificationDocumentType["TAX_RETURN"] = "TAX_RETURN";
    IncomeVerificationDocumentType["STATE_TAX_RETURN"] = "STATE_TAX_RETURN";
    IncomeVerificationDocumentType["BANK_STATEMENT"] = "BANK_STATEMENT";
    IncomeVerificationDocumentType["FORM_1099"] = "FORM_1099";
    IncomeVerificationDocumentType["IRS_DIRECT"] = "IRS_DIRECT";
    IncomeVerificationDocumentType["PROFIT_AND_LOSS"] = "PROFIT_AND_LOSS";
    IncomeVerificationDocumentType["SOCIAL_SECURITY"] = "SOCIAL_SECURITY";
    IncomeVerificationDocumentType["PENSION"] = "PENSION";
    IncomeVerificationDocumentType["RETIREMENT"] = "RETIREMENT";
    // Non uploaded docs
    IncomeVerificationDocumentType["PLAID"] = "PLAID";
    IncomeVerificationDocumentType["WORK_NUMBER"] = "WORK_NUMBER";
    IncomeVerificationDocumentType["EXPERIAN"] = "EXPERIAN";
    //  Special value
    IncomeVerificationDocumentType["STATED_INCOME"] = "STATED_INCOME";
    IncomeVerificationDocumentType["IGNORED_DOC"] = "IGNORED_DOC";
})(IncomeVerificationDocumentType = exports.IncomeVerificationDocumentType || (exports.IncomeVerificationDocumentType = {}));
/** @TranslateToPython @TranslateToEnum */
var ManualIncomeVerificationSource;
(function (ManualIncomeVerificationSource) {
    ManualIncomeVerificationSource["MANUAL"] = "MANUAL";
    ManualIncomeVerificationSource["OCROLUS"] = "OCROLUS";
    ManualIncomeVerificationSource["GOOGLE"] = "GOOGLE";
})(ManualIncomeVerificationSource = exports.ManualIncomeVerificationSource || (exports.ManualIncomeVerificationSource = {}));
/** @TranslateToPython @TranslateToEnum */
var IncomeVerificationDocumentAdditionalInfoKey;
(function (IncomeVerificationDocumentAdditionalInfoKey) {
    IncomeVerificationDocumentAdditionalInfoKey["payPeriodEndDate"] = "payPeriodEndDate";
    IncomeVerificationDocumentAdditionalInfoKey["plaidReportGenerationDate"] = "plaidReportGenerationDate";
})(IncomeVerificationDocumentAdditionalInfoKey = exports.IncomeVerificationDocumentAdditionalInfoKey || (exports.IncomeVerificationDocumentAdditionalInfoKey = {}));
var UnsuccessfulPlaidOwnerNameMatchStatus;
(function (UnsuccessfulPlaidOwnerNameMatchStatus) {
    UnsuccessfulPlaidOwnerNameMatchStatus["noNamesFound"] = "noNamesFound";
    UnsuccessfulPlaidOwnerNameMatchStatus["nameMismatch"] = "nameMismatch";
})(UnsuccessfulPlaidOwnerNameMatchStatus = exports.UnsuccessfulPlaidOwnerNameMatchStatus || (exports.UnsuccessfulPlaidOwnerNameMatchStatus = {}));
var IncomeVerificationResultType;
(function (IncomeVerificationResultType) {
    IncomeVerificationResultType["incomeVerified"] = "incomeVerified";
    IncomeVerificationResultType["incomeNotVerified"] = "incomeNotVerified";
    IncomeVerificationResultType["bankAccountWasFraud"] = "bankAccountWasFraud";
    IncomeVerificationResultType["disableManualIncomeVerificationForSelfEmployedLowFicoGroup"] = "disableManualIncomeVerificationForSelfEmployedLowFicoGroup";
    /**
     * @deprecated for mismatch, we just return incomeNotVerified instead
     */
    IncomeVerificationResultType["_plaidDoesNotMatchApplicantNames"] = "plaidDoesNotMatchApplicantNames";
    IncomeVerificationResultType["loanApplicationStatusDuplicate"] = "loanApplicationStatusDuplicate";
    IncomeVerificationResultType["loanApplicationStatusWithdrawn"] = "loanApplicationWithdrawn";
    IncomeVerificationResultType["cannotInitiateIncomeVerification"] = "cannotInitiateIncomeVerification";
})(IncomeVerificationResultType = exports.IncomeVerificationResultType || (exports.IncomeVerificationResultType = {}));
// keep in line with aven_frontend/aven/src/services/api.ts
var IncomeVerificationPurpose;
(function (IncomeVerificationPurpose) {
    IncomeVerificationPurpose["automatic"] = "automatic";
    IncomeVerificationPurpose["plaid"] = "plaid";
    IncomeVerificationPurpose["irs"] = "irs";
    IncomeVerificationPurpose["manual"] = "manual";
    IncomeVerificationPurpose["ocrolus"] = "ocrolus";
    IncomeVerificationPurpose["_deprecatedDaemon"] = "daemon";
    IncomeVerificationPurpose["staleProgress"] = "staleProgress";
})(IncomeVerificationPurpose = exports.IncomeVerificationPurpose || (exports.IncomeVerificationPurpose = {}));
// delineates all possible outcomes from manual income verification.
var ManualIncomeVerificationResult;
(function (ManualIncomeVerificationResult) {
    ManualIncomeVerificationResult["succeed"] = "succeed";
    ManualIncomeVerificationResult["documentAuthenticityInsufficient"] = "documentAuthenticityInsufficient";
    ManualIncomeVerificationResult["identityInformationInsufficient"] = "identityInformationInsufficient";
    ManualIncomeVerificationResult["doubleEntryIssue"] = "doubleEntryIssue";
    ManualIncomeVerificationResult["fatFingersIssue"] = "fatFingersIssue";
    ManualIncomeVerificationResult["incomeInsufficientForQualOffer"] = "incomeInsufficientForQualOffer";
    ManualIncomeVerificationResult["incomeSufficientForDifferentQualOffer"] = "incomeSufficientForDifferentQualOffer";
    ManualIncomeVerificationResult["incomeSufficientForSameQualOffer"] = "incomeSufficientForSameQualOffer";
    ManualIncomeVerificationResult["humanInvestigate"] = "humanInvestigate";
    ManualIncomeVerificationResult["involuntaryLienVerification"] = "involuntaryLienVerification";
    ManualIncomeVerificationResult["activeForeclosureVerification"] = "activeForeclosureVerification";
    ManualIncomeVerificationResult["ofacVerification"] = "ofacVerification";
})(ManualIncomeVerificationResult = exports.ManualIncomeVerificationResult || (exports.ManualIncomeVerificationResult = {}));
var PaystubProvider;
(function (PaystubProvider) {
    PaystubProvider["ADP"] = "ADP";
    PaystubProvider["Gusto"] = "Gusto";
    PaystubProvider["Paychex"] = "Paychex";
    PaystubProvider["Paycor"] = "Paycor";
    PaystubProvider["Quickbooks"] = "Quickbooks";
})(PaystubProvider = exports.PaystubProvider || (exports.PaystubProvider = {}));
var IncomeVerificationDocumentPendingReason;
(function (IncomeVerificationDocumentPendingReason) {
    IncomeVerificationDocumentPendingReason["INITIAL"] = "INITIAL";
    IncomeVerificationDocumentPendingReason["INSUFFICIENT_INCOME"] = "INSUFFICIENT_INCOME";
    IncomeVerificationDocumentPendingReason["INCORRECT_DOC_TYPE"] = "INCORRECT_DOC_TYPE";
    IncomeVerificationDocumentPendingReason["INCOMPLETE_PAGE"] = "INCOMPLETE_PAGE";
    IncomeVerificationDocumentPendingReason["NO_FILLED_DATA"] = "NO_FILLED_DATA";
    IncomeVerificationDocumentPendingReason["OUTDATED_DOC"] = "OUTDATED_DOC";
    IncomeVerificationDocumentPendingReason["MISSING_INFO"] = "MISSING_INFO";
    IncomeVerificationDocumentPendingReason["MISMATCHED_INFO"] = "MISMATCHED_INFO";
    IncomeVerificationDocumentPendingReason["CROPPED_BLURRY"] = "CROPPED_BLURRY";
    IncomeVerificationDocumentPendingReason["SUSPICIOUS_DOC"] = "SUSPICIOUS_DOC";
    IncomeVerificationDocumentPendingReason["PICTURE_OF_SCREEN"] = "PICTURE_OF_SCREEN";
    IncomeVerificationDocumentPendingReason["PAID_TO_ENTITY"] = "PAID_TO_ENTITY";
    IncomeVerificationDocumentPendingReason["INVALID_NOO_DOC"] = "INVALID_NOO_DOC";
    IncomeVerificationDocumentPendingReason["NO_SCREENSHOTS"] = "NO_SCREENSHOTS";
    IncomeVerificationDocumentPendingReason["ALL_PAGES_OF_BANK_STATEMENTS_REQUIRED"] = "ALL_PAGES_OF_BANK_STATEMENTS_REQUIRED";
    IncomeVerificationDocumentPendingReason["DOC_TYPE_NOT_ALLOWED"] = "DOC_TYPE_NOT_ALLOWED";
    IncomeVerificationDocumentPendingReason["DOC_IS_DUPLICATE_UPLOAD"] = "DOC_IS_DUPLICATE_UPLOAD";
    IncomeVerificationDocumentPendingReason["RECEIVED_DOCS_INSUFFICIENT_REQUIRE_Y_MINUS_ONE"] = "RECEIVED_DOCS_INSUFFICIENT_REQUIRE_Y_MINUS_ONE";
    IncomeVerificationDocumentPendingReason["PASSWORD_PROTECTED_DOC"] = "PASSWORD_PROTECTED_DOC";
    IncomeVerificationDocumentPendingReason["OCROLUS_AUTHENTICITY_SCORE_TOO_LOW"] = "OCROLUS_AUTHENTICITY_SCORE_TOO_LOW";
    IncomeVerificationDocumentPendingReason["OCROLUS_UNSUPPORTED_DOC"] = "OCROLUS_UNSUPPORTED_DOC";
    IncomeVerificationDocumentPendingReason["GOOGLE_UNSUPPORTED_DOC"] = "GOOGLE_UNSUPPORTED_DOC";
    IncomeVerificationDocumentPendingReason["OTHER"] = "OTHER";
})(IncomeVerificationDocumentPendingReason = exports.IncomeVerificationDocumentPendingReason || (exports.IncomeVerificationDocumentPendingReason = {}));
var IrsFormVerificationStatus;
(function (IrsFormVerificationStatus) {
    IrsFormVerificationStatus["notEligible"] = "notEligible";
    IrsFormVerificationStatus["canStart"] = "canStart";
    IrsFormVerificationStatus["completed"] = "completed";
})(IrsFormVerificationStatus = exports.IrsFormVerificationStatus || (exports.IrsFormVerificationStatus = {}));
