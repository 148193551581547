<script setup lang="ts">
    import { i18n } from '@/utils/i18n'
    import { toFormattedUSDNoCents } from '@/mixins/format'
    import { logEvent } from '@/services/http-client'
    import { flushOpenReplayAndWindowOpen, OPEN_REPLAY_DEFAULT_FLUSH_TIMEOUT_MS } from '@/utils/openReplay'
    import { computed, onMounted, ref } from 'vue'
    import { inspect, logger } from '@/utils/logger'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import { AvenHomeCardUpgradeVariant } from '@/views/avenHomeCardUpgrade.types'

    const upgradeOffersStore = useUpgradeOffersStore()
    const upgradeOfferToDisplay = upgradeOffersStore.upgradeOfferToDisplay

    const upgradeDisclosures = computed(() => {
        return upgradeOffersStore.getUpgradeDisclosures(AvenHomeCardUpgradeVariant.lineSize)
    })

    const displayDisclosures = ref(false)

    const onUpgradeBeardClick = async () => {
        logEvent('click_aven_my_ucc_cardholder_to_home_upgrade_beard', {
            offer: upgradeOfferToDisplay,
        })

        // TODO: Might need to update the Aven My app to open this in mobile browser
        await flushOpenReplayAndWindowOpen(upgradeOffersStore.getUpgradeOfferReturnLink('balanceDetailsBeard'), OPEN_REPLAY_DEFAULT_FLUSH_TIMEOUT_MS)
    }

    const onToggleDisclosures = () => {
        displayDisclosures.value = !displayDisclosures.value
        logEvent('click_aven_my_ucc_cardholder_to_home_upgrade_beard_toggle_disclosures', {
            offer: upgradeOfferToDisplay,
        })
    }

    onMounted(() => {
        logEvent('view_aven_my_ucc_cardholder_to_home_upgrade_beard', {
            offer: upgradeOfferToDisplay,
        })
        logger.info(`UCC cardholder upgrade offer to display in onMounted is ${inspect(upgradeOfferToDisplay)}`)
    })
</script>

<template>
    <div>
        <div
            class="tw-text-white m-2 card-shadow d-flex justify-content-between"
            @click="onUpgradeBeardClick"
            @keydown.enter="onUpgradeBeardClick"
        >
            <div>
                <!--
                Using .stop here means the parent's listener won't respond when the disclosure listener is triggered.
                This is helpful because it makes the entire beard clickable, rather than just small pieces of text.
                -->
                <b><span
                    class="upgrade-offer-line"
                    @click.stop="onToggleDisclosures"
                    @keydown.enter.stop="onToggleDisclosures"
                >
                    {{ toFormattedUSDNoCents(upgradeOfferToDisplay.lineSize) }}<sup>1</sup>
                </span>
                </b>
                <span
                    class="tw-me-1"
                    v-html="i18n.t('components.balanceDetailsCard.ucc.upgradeBeard.text')"
                />
            </div>
            <img
                src="@/assets/circle-right-arrow.svg"
                class="upgrade-offer-img"
                alt="continue arrow"
            >
        </div>
        <modal-dialog
            :show="displayDisclosures"
            @close="onToggleDisclosures"
        >
            <div class="tw-px-4">
                <ol class="tw-text-gray-400 tw-p-0 tw-text-[12px]">
                    <li
                        value="1"
                        v-html="upgradeDisclosures"
                    />
                </ol>
            </div>
        </modal-dialog>
    </div>
</template>

<style scoped lang="scss">
    .upgrade-offer-line {
        cursor: pointer;
    }

    .upgrade-offer-img {
        cursor: pointer;
    }
</style>
