<script setup lang="ts">
    import ListRow from '@/components/ListRow.vue'
    import { RoutePaths } from '@/routes/router.types'
    import BalanceDetailsCreditCard from '@/components/BalanceDetailsCreditCard.vue'
    import { computed } from 'vue'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import BalanceDetailsUpgradeToHomeBeard from '@/components/BalanceDetailsUpgradeToHomeBeard.vue'

    interface Props {
        currentBalance: number
        cardSubtitle: string
        hasFullLineSizeContingencies: boolean
        allowedToRequestFullLineSize: boolean
        requestFullCreditLimitTitle: string
    }
    const props = defineProps<Props>()

    const upgradeOffersStore = useUpgradeOffersStore()

    const displayRequestFullCreditLimit = computed(() => {
        return props.hasFullLineSizeContingencies && props.allowedToRequestFullLineSize
    })

    const upgradeOffersToDisplay = computed(() => {
        return upgradeOffersStore.upgradeOfferToDisplay
    })

    const displayUpgradeBeard = computed(() => {
        return !displayRequestFullCreditLimit.value && !!upgradeOffersToDisplay.value
    })
</script>

<template>
    <div
        class="card card-shadow rounded-3"
        :class="{ 'tw-bg-dark-green': displayUpgradeBeard }"
    >
        <balance-details-credit-card
            data-testid="activity-balance"
            :current-balance="currentBalance"
            :sub-title="cardSubtitle"
        />
        <transition name="fade">
            <div
                v-if="displayRequestFullCreditLimit"
                class="d-flex justify-content-center align-items-center my-1 card-shadow"
            >
                <b>
                    <list-row
                        :show-arrow="true"
                        :title="props.requestFullCreditLimitTitle"
                        :href-link="RoutePaths.REQUEST_FULL_CREDIT_LIMIT"
                    />
                </b>
            </div>
            <balance-details-upgrade-to-home-beard v-else-if="displayUpgradeBeard" />
        </transition>
    </div>
</template>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
