import { defineStore } from 'pinia'
import { MAX_IN_APP_CARD_REPLACEMENT } from '@/data/constants'
import {
    AmbassadorConsentStatus,
    AUTO_PAY_DISABLED,
    AutopayCategory,
    AvenCardAccountHolderType,
    AvenCardActivationStatus,
    BalanceTransferBlockReason,
    BT_ALLOWED_ACCOUNT_STANDINGS,
    CustomerAccountOverview,
    CustomerAccountOverviewMetadata,
    DEFAULT_PIF_REWARD_TYPE,
    PifData,
    ProductCategory,
    UpdateAccountOverviewArgs,
} from '@/store/overviewStore.types'
import {
    FirstTimeUserExperienceType,
    ActivationRequirementStatus,
    AccountActivationRequirementStatus,
    AvenCardProductType,
    INCOMPLETE_ACCOUNT_ACTIVATION_REQUIREMENT_STATUSES,
    PifRewardType,
    AvenAccountStanding,
    CardActivationError,
    ActivateCardResponse,
} from 'aven_types'
import { useCustomerInfoStore } from '@/store/customerInfoStore'
import { DebtProductAndMetaDataSet, getAccountBalanceTransferAndCashOutData, getDebtProductAndMetaData, replaceCard } from '@/services/avenAppApi'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { acceptToBeAmbassador, activateAvenCard, declineToBeAmbassador, getCreditScoreHistory, getCreditScores, setFirstTimeUserExperienceIsExperienced } from '@/services/api'
import { API_ERROR, NETWORK_ERROR, parseError } from '@/store/storeUtils'
import { openReplay } from '@/utils/openReplay'
import { inspect, logger } from '@/utils/logger'
import dayjs from 'dayjs'
import ceil from 'lodash/ceil'
import isNil from 'lodash/isNil'
import { appSessionStorage, localStorageKey, sessionStorageKey } from '@/utils/storage'
import { useSessionStorage } from '@vueuse/core'

export const useOverviewStore = () => {
    const overviewStore = defineStore('overview', {
        state: (): Omit<CustomerAccountOverview, 'customerInfo'> & CustomerAccountOverviewMetadata => {
            return {
                // When the app navigates back from the Ascenda external rewards portal, these variables will be
                // in their default state (e.g. null/undefined/etc). That means we can't display the Rewards tab
                // correctly. There are 2 options:
                // 1. Make a call to refresh the account overview
                // 2. Store in session storage
                // Option 1 is not desirable because the page already needs to refresh rewards data, so we'd be adding
                // a long-running API call on top of an already long-running API call. Option 2 thus is the better
                // option from a UX perspective. On top of option 2, we also will refresh the overview in the background
                // so other pages render correctly.
                productType: useSessionStorage('productType', AvenCardProductType._NONE) as unknown as AvenCardProductType,
                isAccountRewardsEnabled: useSessionStorage('isAccountRewardsEnabled', null) as unknown as boolean | null,
                isNowCoreCardEndOfDayProcessingTime: useSessionStorage('isNowCoreCardEndOfDayProcessingTime', false) as unknown as boolean | null,
                dollarToCashbackRewardPointsConversion: useSessionStorage('dollarToCashbackRewardPointsConversion', 0) as unknown as number | null,
                shouldUpdateCashBackAfterDisableAutoPay: useSessionStorage('shouldUpdateCashBackAfterDisableAutoPay', false) as unknown as boolean | null,
                cashbackAfterDisableAutoPay: useSessionStorage('cashbackAfterDisableAutoPay', 0) as unknown as number | null,
                cashbackAfterEnableAutoPay: useSessionStorage('cashbackAfterEnableAutoPay', 0) as unknown as number | null,
                cashbackOnRewardsPortalHotelPurchases: useSessionStorage('cashbackOnRewardsPortalHotelPurchases', undefined) as unknown as number | undefined,
                cardType: useSessionStorage('cardType', AvenCardAccountHolderType.UNKNOWN) as unknown as AvenCardAccountHolderType,
                pifData: useSessionStorage('pifData', undefined, {
                    serializer: {
                        read: (raw: any) => (raw ? JSON.parse(raw) : undefined),
                        write: (value: any) => JSON.stringify(value),
                    },
                }) as unknown as PifData | undefined,
                // TODO: the following links don't have to be in session storage. Can be '' like groupCardholderPromotionTermsLink
                cashbackTermsAndConditionsLink: useSessionStorage('cashbackTermsAndConditionsLink', undefined) as unknown as string | undefined,
                costcoRewardsDocumentLink: useSessionStorage('costcoRewardsDocumentLink', undefined) as unknown as string | undefined,
                restaurants7PercentPromotionTermsLink: useSessionStorage('restaurants7PercentPromotionTermsLink', undefined) as unknown as string | undefined,
                amazonPrimePromotionTermsLink: useSessionStorage('amazonPrimePromotionTermsLink', undefined) as unknown as string | undefined,
                gasAndGroceries7PercentPromotionTermsLink: '',
                groupCardholderPromotionTermsLink: '',
                costcoDisclosuresText: '',
                guaranteedLowestRateDisclosuresText: '',
                lastSuccessfulSync: null,
                rescissionInfo: undefined,
                cardActivationStatus: AvenCardActivationStatus.unknown,
                cardShippingDate: null,
                isAccountChargedOff: false,
                showEnrollAutoPayAndKeepAprDiscount: false,
                showLaWildfireCoBtUnblockInterstitial: false,
                showSimpleInterestConversionInterstitial: false,
                simpleInterestConversionSavings: undefined,
                simpleInterestMonthlyPaymentDecrease: undefined,
                simpleInterestLoanConversionType: undefined,
                simpleInterestLoanConversionSavingDetails: undefined,
                alreadyShownEnrollInAutoPayAndKeepAprDiscount: false,
                alreadyShownLaWildfireCoBtUnblockInterstitialInThisSession: false,
                alreadyShownSimpleInterestConversionInterstitialInThisSession: false,
                alreadyShownDqInterstitialInThisSession: false,
                alreadyShownInstantBalanceTransferInOnboarding: false,
                alreadyShownPrimeV3CashOutInActivation: false,
                minAccountFico: undefined,
                didAcceptMethodFiTos: null,
                ambassadorConsentStatus: undefined,
                isAccountBTCOEnabled: null,
                isAccountCOEnabled: null,
                isAccountBTEnabled: null,
                isAccountCashOutUpfrontV1Enabled: null,
                isAccountCashOutUpfrontV2Enabled: null,
                cashOutUpfrontTransferAmountWithFee: null,
                isAccountInstantBTEnabled: null,
                shouldShowInstantBtDuringOnboarding: null,
                shouldShowAvenHomeCardUpgradeInterstitial: null,
                shouldShowAvenHomeCardUpgradeNotTakenSurvey: null,
                apr: 0,
                isPurchaseInTeaserPromoPeriod: false,
                purchasePromoAprPercent: undefined,
                purchasePromoEndDate: undefined,
                billingDay: 0,
                accountNumber: '',
                creditLimit: 0,
                limitedModeCreditLimit: 0,
                propertyFullAddress: '',
                existingHomeReAttach: Object.create(null),
                expiringFloodInsurance: Object.create(null),
                monthlyFee: 0,
                productCategory: ProductCategory.basic,
                desiredProductCategory: ProductCategory.basic,
                showPrimeOption: false,
                loanTermOptions: [],
                currentBalance: 0,
                statementRemainingBalance: 0,
                minimumDue: 0,
                // This is a calculated value from backend (not a native corecard value)
                amountPastDue: 0,
                dqCuredAt: undefined,
                isPasscodeSet: false,
                avenAccountStanding: undefined,
                daysDelinquent: 0,
                isEligibleForPaymentOptions: false,
                nextStatementDate: '',
                paymentDueDate: '',
                autopayNextPaymentDisplayDate: '',
                availableCredit: 0,
                showCashOutFeature: null,
                isUpfrontCashOutEnabled: null,
                showBalanceTransferFeature: null,
                cashOutTotalAmountWithFeeMultiplier: 0,
                balanceTransferTotalAmountWithFeeMultiplier: 0,
                cashOutFee: 0,
                isCashOutInTeaserPromoPeriod: false,
                cashOutPromoType: undefined,
                cashOutPromoAprPercent: undefined,
                cashOutPromoPeriodInMonths: undefined,
                cashOutPromoEndDate: undefined,
                balanceTransferFee: 0,
                isBalanceTransferInTeaserPromoPeriod: false,
                balanceTransferPromoType: undefined,
                balanceTransferPromoAprPercent: undefined,
                balanceTransferPromoFeeRatio: undefined,
                balanceTransferPromoPeriodInMonths: undefined,
                balanceTransferPromoEndDate: undefined,
                totalPendingCashOutPlusBalanceTransfers: 0,
                madeRecentLargePayment: false,
                last4CardNumber: '',
                scheduledPayments: [],
                payItForwardTipInfo: {
                    maxAmount: 0,
                    isEligible: false,
                    sourceName: '',
                },
                signOnBonus: {
                    type: '',
                    bonusAmount: 0,
                    sourceName: '',
                },
                fastFunding: {
                    isEligible: false,
                    isApproved: undefined,
                    hasSubmitted: undefined,
                    lineSizeIfApproved: undefined,
                    lineSizePostCardActivation: undefined,
                    decisionETA: undefined,
                },
                naturalDisasterAreaVideoAndLocation: {
                    status: ActivationRequirementStatus.passed,
                },
                advanceTransactionNotification: {
                    isEligibleToCreate: false,
                    mostRecentOngoing: undefined,
                },
                isMailerActivationEnabled: false,
                isFixedTermUser: false,
                allowLongTermInstallmentLoans: false,
                mortgagePaymentRewardsEnabled: false,
                mortgagePaymentRewardsV2Enabled: false,
                maxTotalDailyBtPlusCashOutInDollars: undefined,
                numberOfCancelledCards: MAX_IN_APP_CARD_REPLACEMENT,
                enableAmortizationLoan: false,
                allowSubmitTransactionDisputeInApp: false, // Disputes v1.0
                allowSubmitTransactionDisputeQuestionnaireInApp: false, // Disputes v2.0
                autoPaySetting: AUTO_PAY_DISABLED,
                btcDepositAddress: '',
                isInMarginCall: false,
                isFirstSessionForCustomer: false,
                returnToken: '',
                aprIncrementPercentForFixedRateInstallmentLoan: 2,
                fixedInstallmentApr: 0,
                isSimpleInterestLoanEnabled: false,
                minMonthlyPaymentFloor: 0,
                maxCashOutAmountPerTransaction: 0,
                isMaxCashOutAmountPerTransactionOverDailyLimit: false,
                allowFullDrawCashOut: false,
                maxBalanceTransferAmountPerTransaction: 0,
                isMaxBalanceTransferAmountPerTransactionOverDailyLimit: false,
                isCreditLimitReinstatementEligible: false,
                previousCreditLimit: 0,
                experimentOverrides: undefined,
                isHausMember: false,
                isPrimeV3Offer: false,
                showPrimeV3InActivation: false,
                primeV3PromoAprPercent: undefined,
                hasPrimeV3CoBtFeeDiscount: false,
                primeV3PromoCoFee: undefined,
                primeV3PromoBtFee: undefined,
                primeV3RemainingLineDrawAmount: undefined,
                primeV3CutOffDateStr: undefined,
                isTestingEnvironment: false,
                isInternalEmployee: false,
                currentMegaphone: undefined,
                excessPaymentAllocationMethod: undefined,
                canAskForExcessPaymentAllocationMethod: false,
                hasAuthorizedCardholders: false,
                limitedSecondaryAccess: false,
                fullLineSizeContingencies: [],
                allowedToRequestFullLineSize: false,
                fullLineSize: 0,
                creditLimitIncreaseApplicationUrl: undefined,
                showEngagementLinkBankAccountCard: false,
                hasCreditLineIncrease: false,
                cashOutV2BannerParams: undefined,
                activeMortgagePaymentRewardsStatus: undefined,
                hasActivationRequirements: undefined,
                fixedTermActivationRequirementStatus: undefined, // deprecated
                activationRequirementStatus: undefined,
                isBlockedByContingencies: false,
                blockingContingencyError: undefined,
                originationFeeRatio: 0,
                originationFeeAmount: 0,
                firstDrawFeeRatio: 0,
                firstDrawFeeAmount: 0,
                firstDrawAmountExcludingFee: 0,
                firstDrawAmountIncludingFee: 0,
                totalAchPaymentAmountFromAciTodayDollarsData: undefined,
                mortgagePaymentRewardsEarned: undefined,
                mortgagePaymentRewardsV2AvailableSpots: undefined,
                ficoScore: null,
                vantageScore: null,
                dateOfScores: null,
                dateOfNextScores: null,
                needFull9ssn: false,
                isExperianFrozen: false,
                creditScoreHistory: null,
                denyRevolvingCoBt: false,
                isFullLineMinDrawProduct: false,
                showGuaranteedForeclosureProtection: null,
                isLoyaltyProduct: false,
                installmentPlanAprPremium: 0,
                loanTerms: undefined,
                underwritingMetadata: undefined,
                avenBoostRewards: [],
                isEnrolledInMethodfi: false,
                isIncomeVerified: undefined,
                hasAttemptedIdentityChecks: undefined,
                hasCoBtUnavailableUntilVideoWalkthroughAndLocationReviewAccountFeatureContingency: false,
                avenUpgradeData: [],
                personalFinancialLiabilitiesLogos: [],
                showPaymentFraudBanner: false,
            }
        },

        getters: {
            isOverViewInitialized: (state) => !isNil(state.lastSuccessfulSync),
            accountIsClosed: (state) => state.avenAccountStanding === AvenAccountStanding.closed,
            isPrimaryCard: (state) => state.cardType === AvenCardAccountHolderType.PRIMARY,
            isCoApplicantCard: (state) => state.cardType === AvenCardAccountHolderType.CO_APPLICANT,
            isCoOwnerCard: (state) => state.cardType === AvenCardAccountHolderType.CO_OWNER,
            isCryptoProduct: (state) => state.productType === AvenCardProductType.CRYPTO,
            isAutoProduct: (state) => state.productType === AvenCardProductType.AUTO,
            isHomeProduct: (state) =>
                [AvenCardProductType.HOME, AvenCardProductType.HOME_NO_LOYALTY, AvenCardProductType.HOME_LIMITED_MODE, AvenCardProductType.HOME_SECURED_PLUS].includes(state.productType),
            isUccProduct: (state) => state.productType === AvenCardProductType.ASSET_ATTESTATION,
            hasOngoingActivationRequirement: (state) => INCOMPLETE_ACCOUNT_ACTIVATION_REQUIREMENT_STATUSES.includes(state.activationRequirementStatus),
            cardRequiresActivation: (state) =>
                [
                    AvenCardActivationStatus.pending,
                    AvenCardActivationStatus.last4DigitValidated,
                    AvenCardActivationStatus.cashOutFullDrawInProgress,
                    AvenCardActivationStatus.cashOutFullDrawError,
                    AvenCardActivationStatus.internalError,
                    AvenCardActivationStatus._exceededMaxOnlineActivationAttempts,
                    AvenCardActivationStatus.cashOutFullDrawDrafted,
                    AvenCardActivationStatus.mailerCodeValidated,
                ].includes(state.cardActivationStatus) ||
                INCOMPLETE_ACCOUNT_ACTIVATION_REQUIREMENT_STATUSES.includes(state.activationRequirementStatus) ||
                // deprecate hasActivationRequirements
                state.hasActivationRequirements,
            isLimitedMode: (state) => state.productType === AvenCardProductType.HOME_LIMITED_MODE,
            nextAutoPayAmount: (state) => {
                let amount = 0
                const autoPayDetails = state.autoPaySetting.autoPayDetails
                if (autoPayDetails?.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT) {
                    amount = Math.min(autoPayDetails.customAmount, state.statementRemainingBalance)
                } else if (autoPayDetails?.autoPayCategory === AutopayCategory.STATEMENT_BALANCE) {
                    amount = state.statementRemainingBalance
                } else if (autoPayDetails?.autoPayCategory === AutopayCategory.MINIMUM_DUE) {
                    amount = state.minimumDue
                }
                return amount
            },
            showSignOnBonusRealEstateAgentScreen: (state) => state.signOnBonus.type === 'realEstateAgent' && !state.isAccountBTCOEnabled,
            balanceTransferBlockReason: (state) => {
                if (!BT_ALLOWED_ACCOUNT_STANDINGS.includes(state.avenAccountStanding)) {
                    return BalanceTransferBlockReason.ACCOUNT_NOT_CURRENT
                } else if (![AvenCardAccountHolderType.PRIMARY, AvenCardAccountHolderType.CO_APPLICANT].includes(state.cardType)) {
                    return BalanceTransferBlockReason.NOT_PRIMARY
                } else if (state.rescissionInfo?.isInRescissionPeriod) {
                    return BalanceTransferBlockReason.IN_RESCISSION
                } else if (!state.isAccountBTEnabled) {
                    return BalanceTransferBlockReason.CARD_NOT_ACTIVATED
                } else if (!state.availableCredit) {
                    return BalanceTransferBlockReason.NO_AVAILABLE_CREDIT
                } else if (!state.didAcceptMethodFiTos) {
                    // TODO use this for the accept ToS card
                    return BalanceTransferBlockReason.TOS_NOT_ACCEPTED
                } else {
                    return null
                }
            },
            canShowInstantBalanceTransferOptionsCard(state): {
                canShow: boolean
                showBalanceTransferFeature: boolean | null
                isAccountInstantBTEnabled: boolean | null
                balanceTransferBlockReason: BalanceTransferBlockReason | null
            } {
                const canShow = !!state.showBalanceTransferFeature && !!state.isAccountInstantBTEnabled && !this.balanceTransferBlockReason
                return {
                    canShow,
                    showBalanceTransferFeature: state.showBalanceTransferFeature,
                    isAccountInstantBTEnabled: state.isAccountInstantBTEnabled,
                    balanceTransferBlockReason: this.balanceTransferBlockReason,
                }
            },
            isEligibleForPrimeV3CoFeeDiscount(state): boolean {
                return state.hasPrimeV3CoBtFeeDiscount && !isNil(state.primeV3PromoCoFee) && this.primeV3PromoCoFee > 0
            },
            primeV3TransferAmountWithoutFee(state): number | null {
                if (isNil(state.primeV3RemainingLineDrawAmount)) {
                    logger.info(`primeV3TransferAmountWithoutFee is null because primeV3RemainingLineDrawAmount is ${state.primeV3RemainingLineDrawAmount}`)
                    return null
                } else if (isNil(state.cashOutTotalAmountWithFeeMultiplier) || state.cashOutTotalAmountWithFeeMultiplier === 0) {
                    logger.info(`primeV3TransferAmountWithoutFee is null because cashOutTotalAmountWithFeeMultiplier is ${state.cashOutTotalAmountWithFeeMultiplier}`)
                    return null
                } else {
                    let cashoutFeeMultiplier = state.cashOutTotalAmountWithFeeMultiplier
                    if (this.isEligibleForPrimeV3CoFeeDiscount) {
                        cashoutFeeMultiplier = 1 + state.primeV3PromoCoFee
                        logger.info(`isEligibleForPrimeV3CoFeeDiscount=true, using primeV3PromoCoFee ${state.primeV3PromoCoFee} to calculate cashoutFeeMultiplier ${cashoutFeeMultiplier}`)
                    }
                    const amountWithoutFee = ceil(state.primeV3RemainingLineDrawAmount / cashoutFeeMultiplier, 2)
                    logger.info(
                        `primeV3TransferAmountWithoutFee is (primeV3RemainingLineDrawAmount: ${state.primeV3RemainingLineDrawAmount}) / (cashoutFeeMultiplier: ${cashoutFeeMultiplier}) = ${amountWithoutFee}`
                    )
                    return amountWithoutFee
                }
            },
            cashOutAmountMeetsPrimeV3CoFeeDiscountRequirement(): boolean {
                const cashOutAmountWithoutFee = appSessionStorage.getItem(localStorageKey.cashOutAmount)
                return this.isEligibleForPrimeV3CoFeeDiscount && !isNaN(parseFloat(cashOutAmountWithoutFee)) && parseFloat(cashOutAmountWithoutFee) >= this.primeV3TransferAmountWithoutFee
            },
            shouldShowPrimeV3PromptPostActivation(state): boolean {
                const inputs = {
                    isPrimeV3Offer: state.isPrimeV3Offer,
                    showPrimeV3InActivation: state.showPrimeV3InActivation,
                    primeV3PromoAprPercent: state.primeV3PromoAprPercent,
                    primeV3RemainingLineDrawAmount: state.primeV3RemainingLineDrawAmount,
                    isAccountBTCOEnabled: state.isAccountBTCOEnabled,
                    isMailerActivationEnabled: state.isMailerActivationEnabled,
                    cardActivationStatus: state.cardActivationStatus,
                    cardRequiresActivation: this.cardRequiresActivation,
                    alreadyShownPrimeV3CashOutInActivation: state.alreadyShownPrimeV3CashOutInActivation,
                }
                const shouldShowPrimeV3Prompt =
                    inputs.isPrimeV3Offer &&
                    inputs.showPrimeV3InActivation &&
                    inputs.primeV3PromoAprPercent > 0 &&
                    inputs.primeV3RemainingLineDrawAmount > 0 &&
                    !inputs.alreadyShownPrimeV3CashOutInActivation &&
                    inputs.isAccountBTCOEnabled &&
                    (!inputs.cardRequiresActivation || (inputs.isMailerActivationEnabled && inputs.cardActivationStatus === AvenCardActivationStatus.mailerCodeValidated))
                logger.info(`Result of shouldShowPrimeV3Prompt is ${shouldShowPrimeV3Prompt} w/ inputs ${JSON.stringify(inputs)}`)
                return shouldShowPrimeV3Prompt
            },
            pifRewardType(state): PifRewardType {
                return state.pifData?.rewardType ?? DEFAULT_PIF_REWARD_TYPE
            },
            cashIncreaseCashBackWithAutoPay(state): boolean {
                const currentCashBackRatio = state.dollarToCashbackRewardPointsConversion
                const cashBackRatioAutoPayOn = state.cashbackAfterEnableAutoPay
                return currentCashBackRatio < cashBackRatioAutoPayOn
            },
            pendingUpfrontCashOut(state): boolean {
                // should start the Upfront CashOut flow only when the customer has validated with mailer code or card's last4,
                // i.e. cardActivationStatus is not pending anymore, then check the activation requirement status, as
                // well as the Upfront transfer amount
                return (
                    state.avenAccountStanding === AvenAccountStanding.current &&
                    !state.isBlockedByContingencies &&
                    state.cardActivationStatus !== AvenCardActivationStatus.pending &&
                    state.activationRequirementStatus === AccountActivationRequirementStatus.pending &&
                    state.firstDrawAmountExcludingFee > 0 &&
                    state.isUpfrontCashOutEnabled
                )
            },
            shouldStartUpfrontCashOutFlow(): boolean {
                // only primary or co-applicant card can start the activation CashOut flow
                return this.pendingUpfrontCashOut && (this.isPrimaryCard || this.isCoApplicantCard)
            },
            pendingUpfrontCashOutForCoOwnerCard(): boolean {
                // pending to start the Upfront CashOut flow but current card is a co-owner, also intentionally redundant check on !shouldStartUpfrontCashOutFlow
                return this.pendingUpfrontCashOut && this.isCoOwnerCard && !this.shouldStartUpfrontCashOutFlow
            },
            shouldStartUpfrontCashOutV2Flow(state): boolean {
                // TODO: jiayang to confirm
                // remove state.cardActivationStatus and state.activationRequirementStatus requirement on shouldStartUpfrontCashOutFlow
                return state.avenAccountStanding === AvenAccountStanding.current && !state.isBlockedByContingencies && state.firstDrawAmountExcludingFee > 0
            },
            shouldShowUpfrontCashOutInProgress(state): boolean {
                return state.activationRequirementStatus === AccountActivationRequirementStatus.inProgress
            },
        },
        actions: {
            patchOverviewAndCustomerInfo(updatedOverview: Partial<CustomerAccountOverview>) {
                this.$patch({
                    ...updatedOverview,
                })
                useCustomerInfoStore().$patch({
                    ...updatedOverview.customerInfo,
                })
            },
            /**
             * Updates the *entire* account overview. This is an $$ expensive $$ API call, so use sparingly.
             * @param freshEnoughDate If the last successful sync is this Date or more recent, then we will not sync again.
             * @param debtProductAndMetaDataSet Which data set to return in the call to getDebtProductAndMetaData().
             */
            async updateAccountOverview({ freshEnoughDate, debtProductAndMetaDataSet }: UpdateAccountOverviewArgs) {
                const globalUiStore = useGlobalUiStore()
                try {
                    if (freshEnoughDate && this.lastSuccessfulSync && dayjs(this.lastSuccessfulSync).isSameOrAfter(dayjs(freshEnoughDate))) {
                        logger.info(`Account overview last synced ${this.lastSuccessfulSync}, which is at least as recent as ${freshEnoughDate}, not syncing again`)
                        return
                    }
                    logger.info(`Syncing account overview. Last successful sync was ${this.lastSuccessfulSync}.${freshEnoughDate ? ` Fresh enough date is ${freshEnoughDate}` : ''}`)
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })

                    const [{ data: accountBtCoDataResponseData }, { data: debtProductAndMetaDataResponseData }] = await Promise.all([
                        getAccountBalanceTransferAndCashOutData(),
                        getDebtProductAndMetaData(debtProductAndMetaDataSet),
                    ])
                    if (accountBtCoDataResponseData.success && debtProductAndMetaDataResponseData.success) {
                        this.patchOverviewAndCustomerInfo({
                            ...accountBtCoDataResponseData.payload,
                            ...debtProductAndMetaDataResponseData.payload,
                        })

                        if (debtProductAndMetaDataSet === DebtProductAndMetaDataSet.all) {
                            appSessionStorage.setItem(sessionStorageKey.experimentsOverrides, JSON.stringify(debtProductAndMetaDataResponseData.payload.experimentOverrides || {}))
                        }

                        this.lastSuccessfulSync = new Date()
                    } else {
                        // Todo Unclear whether globalUiStore.error is used correctly or whether we should use it at all.
                        globalUiStore.error = API_ERROR
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async activateCard(
                last4DigitsAvenCard: string,
                expirationMmYy: string
            ): Promise<
                | { success: true }
                | {
                      success: false
                      error: CardActivationError
                      errorPayload: ActivateCardResponse
                  }
            > {
                const globalUiStore = useGlobalUiStore()
                // for local dev purpose
                // console.log('****** /src/store/index.ts FAKED SUCCESSFUL CARD ACTIVATION *********')
                // useOverviewStore().cardActivationStatus = AvenCardActivationStatus.activated
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await activateAvenCard(last4DigitsAvenCard, expirationMmYy)
                    if (data.success || data.error === CardActivationError.CARD_ALREADY_ACTIVATED) {
                        console.log(`data from response: ${JSON.stringify(data)}`)
                        console.log(`data.payload.updatedActivationStatus: ${data.payload?.updatedActivationStatus}`)
                        useOverviewStore().cardActivationStatus = (data.payload?.updatedActivationStatus as AvenCardActivationStatus | undefined) ?? AvenCardActivationStatus.activated
                        useOverviewStore().shouldShowAvenHomeCardUpgradeInterstitial = data.payload?.shouldShowAvenHomeCardUpgradeInterstitial ?? false
                        return { success: true }
                    } else if (Object.values(CardActivationError).find((e) => e === data.error)) {
                        globalUiStore.error = data.error
                        if ([CardActivationError.ERROR_IN_RESCISSION_PERIOD, CardActivationError.ERROR_CARD_ACTIVATION_OTHER_OWNER].includes(data.error as CardActivationError)) {
                            globalUiStore.errorPayload = data.payload
                        }
                        // Todo There are still some spots in the code that use globalUiStore.error & globalUiStore.errorPayload
                        //  for failures of this method, but there's no reason for that; they should just use this response.
                        return {
                            success: false,
                            error: data.error as CardActivationError,
                            errorPayload: data.payload,
                        }
                    } else {
                        throw new Error(`Unknown card activation with data: ${JSON.stringify(data)}`)
                    }
                } catch (e) {
                    parseError(e)
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async replaceCard(payload) {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const response = await replaceCard(payload)
                    if (!response.data.payload.success) {
                        globalUiStore.error = response.data.error
                    } else {
                        useOverviewStore().$patch({
                            cardActivationStatus: AvenCardActivationStatus.pending,
                            numberOfCancelledCards: response.data.payload.numberOfCancelledCards,
                        })
                    }
                } catch (e) {
                    parseError(e)
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async trySetHasSeenInstantBtDuringOnboarding() {
                try {
                    logger.info(`Setting ${FirstTimeUserExperienceType.viewInstantBtDuringOnboarding} as experienced`)
                    await setFirstTimeUserExperienceIsExperienced(FirstTimeUserExperienceType.viewInstantBtDuringOnboarding)
                    this.shouldShowInstantBtDuringOnboarding = false
                    logger.info(`Successfully set ${FirstTimeUserExperienceType.viewInstantBtDuringOnboarding} as experienced`)
                } catch (e) {
                    logger.fatal(`Error setting ${FirstTimeUserExperienceType.viewInstantBtDuringOnboarding} as experienced`, e)
                }
            },
            async trySetHasSeenAvenHomeCardUpgradeInterstitial() {
                try {
                    logger.info(`Setting ${FirstTimeUserExperienceType.viewAvenHomeCardUpgradeInterstitial} as experienced`)
                    await setFirstTimeUserExperienceIsExperienced(FirstTimeUserExperienceType.viewAvenHomeCardUpgradeInterstitial)
                    this.shouldShowAvenHomeCardUpgradeInterstitial = false
                    logger.info(`Successfully set ${FirstTimeUserExperienceType.viewAvenHomeCardUpgradeInterstitial} as experienced`)
                } catch (e) {
                    logger.fatal(`Error setting ${FirstTimeUserExperienceType.viewAvenHomeCardUpgradeInterstitial} as experienced`, e)
                }
            },
            async tryAcceptToBeAmbassador() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await acceptToBeAmbassador()
                    if (data.success) {
                        logger.info('Successfully accepted to be ambassador')
                        useOverviewStore().ambassadorConsentStatus = AmbassadorConsentStatus.ACCEPTED
                    } else {
                        throw new Error(`Unknown error while accepting to be ambassador: ${JSON.stringify(data)}`)
                    }
                } catch (e) {
                    parseError(e)
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async tryDeclineToBeAmbassador() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await declineToBeAmbassador()
                    if (data.success) {
                        logger.info('Successfully declined to be ambassador')
                        useOverviewStore().ambassadorConsentStatus = AmbassadorConsentStatus.DENIED
                    } else {
                        throw new Error(`Unknown error while declining to be ambassador: ${JSON.stringify(data)}`)
                    }
                } catch (e) {
                    parseError(e)
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },

            updateCreditScoreData(payload: {
                ficoScore: number | undefined
                vantageScore: number | undefined
                dateOfScores: Date | undefined
                dateOfNextScores: Date | undefined
                needFull9ssn: boolean | undefined
                isExperianFrozen: boolean | undefined
            }) {
                this.ficoScore = payload.ficoScore ?? this.ficoScore
                this.vantageScore = payload.vantageScore ?? this.vantageScore
                this.dateOfScores = payload.dateOfScores ?? this.dateOfScores
                this.dateOfNextScores = payload.dateOfNextScores ?? this.dateOfNextScores
                this.needFull9ssn = payload.needFull9ssn ?? this.needFull9ssn
                this.isExperianFrozen = payload.isExperianFrozen ?? this.isExperianFrozen
            },
            async maybeFetchCreditScoreData() {
                try {
                    const creditScoresResponse = await getCreditScores()

                    if (creditScoresResponse.data.success) {
                        this.updateCreditScoreData({
                            ficoScore: creditScoresResponse.data.payload.ficoScore,
                            vantageScore: creditScoresResponse.data.payload.vantageScore,
                            dateOfScores: creditScoresResponse.data.payload.dateOfScores,
                            dateOfNextScores: creditScoresResponse.data.payload.dateOfNextScores,
                        })
                    } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.NEED_FULL_9_SSN) {
                        logger.log(`User needs full SSN to get credit scores`)
                        this.updateCreditScoreData({
                            needFull9ssn: true,
                        })
                    } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.EXPERIAN_FROZEN_ERROR) {
                        logger.log(`Experian file frozen for User`)
                        this.updateCreditScoreData({
                            isExperianFrozen: true,
                        })
                    } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.NO_SCORE_AVAILABLE_ERROR) {
                        logger.error(`No credit scores available for User`)
                    } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.USER_IS_A_MINOR) {
                        logger.error(`Experian indicates user is a minor`)
                    } else {
                        logger.error(`Failed to get a credit score and the error was unrecognized: ${creditScoresResponse.data.error}. See backend logs for more info.`)
                    }
                } catch (e) {
                    logger.error(`Error loading Aven Advisor credit score data`, e)
                }

                try {
                    const response = await getCreditScoreHistory()
                    if (response.status === 200 && response.data.success) {
                        this.creditScoreHistory = response.data.payload.history ?? this.creditScoreHistory
                    } else {
                        logger.error(`Aven Advisor failed to load credit score history with error: ${inspect(response.data.error)}`)
                    }
                } catch (e) {
                    logger.error(`Error fetching Aven Advisor credit score history`, e)
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(overviewStore)

    return overviewStore
}
// Keep in sync with GetAvenAdvisorCreditScoresErrors in aven_frontend/aven_advisor/src/services/api.ts
enum GetAvenAdvisorCreditScoresErrors {
    NEED_FULL_9_SSN = 'NEED_FULL_9_SSN',
    REPORT_FETCH_ERROR = 'REPORT_FETCH_ERROR',
    EXPERIAN_FROZEN_ERROR = 'EXPERIAN_FROZEN_ERROR',
    NO_SCORE_AVAILABLE_ERROR = 'NO_SCORE_AVAILABLE_ERROR',
    USER_IS_A_MINOR = 'USER_IS_A_MINOR',
}
