<template>
    <div
        v-if="showAlert"
        class="banner"
        data-testid="account-status-banner"
        :class="`banner-${alertName}`"
    >
        <a
            v-if="showClose"
            class="back banner-close"
            @click="handleClose"
            @keydown.enter="handleClose"
            data-testid="account-status-close"
        >
            <img
                src="@/assets/images/global/close.svg"
                :alt="$t('accessibility.close')"
            >
        </a>
        <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
            <div v-if="alertName === 'megaphone'">
                <h5
                    class="banner-heading mb-1 fw-bold"
                    data-testid="account-status-banner-title"
                >
                    {{ this.currentMegaphone.title }}
                </h5>
                <p data-testid="account-status-banner-description">
                    <span v-html="this.currentMegaphone.description" />
                </p>
            </div>
            <div v-else>
                <h5
                    class="banner-heading fw-bold mb-1"
                    data-testid="account-status-banner-title"
                >
                    {{ $t(`components.accountStatusBanner.${alertName}${getDelinquentLabelKey}.title`, { limitedModeCreditLimit: toFormattedUSDNoCents(this.limitedModeCreditLimit) }) }}
                </h5>
                <p data-testid="account-status-banner-description">
                    {{
                        $t(`components.accountStatusBanner.${alertName}${getDelinquentLabelKey}.description`, {
                            amount: toFormattedUSD(amountPastDue),
                            limitedModeCreditLimit: toFormattedUSDNoCents(this.limitedModeCreditLimit),
                            floodExpiryDate: this.expiringFloodInsurance ? this.expiringFloodInsurance.expiryDate : '',
                            rescissionEndDate: endRescissionStr,
                            dqPaymentProcessEstimate,
                        })
                    }}
                </p>
            </div>

            <div
                v-if="shouldShowButtonOnAccountStatusBanner"
                class="w-100 d-grid gap-2"
            >
                <a
                    :class="`btn btn-${alertName}`"
                    data-testid="account-status-banner-cta"
                    @click.prevent="btnOnClickHandler"
                    @keydown.prevent="btnOnClickHandler"
                >
                    {{ cta }}
                </a>
                <a
                    v-if="isDelinquent"
                    :class="`btn btn-${alertName}`"
                    data-testid="account-status-banner-dq-schedule-a-call-cta"
                    @click.prevent="btnOnScheduleACall"
                    @keydown.prevent="btnOnScheduleACall"
                >
                    Schedule A Call
                </a>
                <a
                    v-if="canOfferPastDueAcccountReage"
                    :class="`btn btn-${alertName}`"
                    data-testid="account-status-banner-get-help-cta"
                    @click.prevent="btnOnGetHelp"
                    @keydown.prevent="btnOnGetHelp"
                >
                    {{ $t(`components.accountStatusBanner.delinquent.getHelp`) }}
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import format from '@/mixins/format'
    import generic from '@/utils/generic'
    import { useOverviewStore } from '@/store/overviewStore'
    import { AvenAccountStanding } from 'aven_types'
    import { RouteNames } from '@/routes/router.types'
    import { dismissMegaphone } from '@/services/api'
    import dayjs from 'dayjs'
    import { DISPLAY_DATE_FORMAT } from '@/data/constants'
    import dqPaymentOptionsMixin from '@/mixins/dqPaymentOptionsMixin'
    import { navigationTabRouteNames } from '@/utils/navigationTabs'
    import { AccountStatusAlertName } from 'aven_types'
    import { assertNever } from 'aven_shared/src/utils/assertUtils'

    const avenAccountStandingsForShowingAlerts = new Map<AvenAccountStanding, AccountStatusAlertName>([
        [AvenAccountStanding.delinquent, AccountStatusAlertName.delinquent],
        [AvenAccountStanding.pastDue, AccountStatusAlertName.pastDue],
        [AvenAccountStanding.overLimit, AccountStatusAlertName.overLimit],
        [AvenAccountStanding.fraud, AccountStatusAlertName.fraud],
        [AvenAccountStanding.blocked, AccountStatusAlertName.blocked],
        [AvenAccountStanding.closed, AccountStatusAlertName.closed],
        [AvenAccountStanding.pendingClose, AccountStatusAlertName.pendingClose],
        [AvenAccountStanding.pendingPayoffThenLimitedMode, AccountStatusAlertName.pendingPayoffThenLimitedMode],
        [AvenAccountStanding.limitedMode, AccountStatusAlertName.limitedMode],
        [AvenAccountStanding.homeReAttachInProgress, AccountStatusAlertName.homeReAttachInProgress],
        [AvenAccountStanding.homeReAttachInRescission, AccountStatusAlertName.homeReAttachInRescission],
        [AvenAccountStanding.expiringFloodInsurance, AccountStatusAlertName.expiringFloodInsurance],
        [AvenAccountStanding.expiredFloodInsurance, AccountStatusAlertName.expiredFloodInsurance],
        [AvenAccountStanding.paymentProcessingAfterPastDue, AccountStatusAlertName.paymentProcessingAfterPastDue],
    ])

    const PAYMENT_PROCESSING_LAG_DAYS = 10
    export default {
        name: 'AccountStatusBanner',
        mixins: [format, dqPaymentOptionsMixin],
        data: function () {
            return {
                canOfferLateFeeReversal: false,
                canOfferPastDueAcccountReage: false,
            }
        },
        computed: {
            showAlert: function () {
                const inputVars = {
                    alertName: this.alertName,
                    routeName: this.$route.name,
                    isAccountChargedOff: this.isAccountChargedOff,
                    accountStanding: this.accountStanding,
                    isDelinquent: this.isDelinquent,
                    currentMegaphone: this.currentMegaphone,
                }
                logger.info(`Determining whether to show account status banner: ${JSON.stringify(inputVars)}`)
                let result

                // If the account is charged off, we won't show anything about the account status
                // because we expect our debt collectors to handle the communication of that fact
                // and anything we show in app has the potential to be misconstrued.
                if (this.isAccountChargedOff) {
                    result = false
                }
                // show account closed or pendingClose banner everywhere
                else if (
                    this.accountStanding === AvenAccountStanding.closed ||
                    this.accountStanding === AvenAccountStanding.pendingClose ||
                    this.accountStanding === AvenAccountStanding.pendingPayoffThenLimitedMode
                ) {
                    result = true
                } else {
                    const showOnRoute = navigationTabRouteNames.some((name) => name === this.$route.name)
                    result =
                        showOnRoute &&
                        // A DQ customer may still have active account standing, when they just become dq (before we block them), or their payment is recently reversed
                        (avenAccountStandingsForShowingAlerts.has(this.accountStanding) || this.isDelinquent || !!this.currentMegaphone || this.showPaymentFraudBanner)
                }

                if (result) {
                    this.$logEvent('view_status_banner', inputVars)
                } else {
                    logger.info(`Not showing account status banner. Inputs: ${JSON.stringify(inputVars)}`)
                }

                this.$emit('show', 'AccountStatusBanner', result ? 'show' : 'hide')
                return result
            },
            showClose: function () {
                return this.alertName === AccountStatusAlertName.megaphone
            },
            // If you add a new alertName, make sure to update the accountStatusBanner in aven_types
            alertName: function (): AccountStatusAlertName | null {
                logger.info(`AccountStanding = ${this.accountStanding}`)

                if (this.isDelinquent) {
                    return AccountStatusAlertName.delinquent
                }
                if (avenAccountStandingsForShowingAlerts.has(this.accountStanding)) {
                    if (this.showPaymentFraudBanner) {
                        // If true, the account is blocked. We want users who are blocked and who have made suspicious payments
                        // to see the suspicious payment banner, instead of the blocked banner
                        return AccountStatusAlertName.suspiciousPaymentFraud
                    }
                    return avenAccountStandingsForShowingAlerts.get(this.accountStanding)
                }
                if (this.currentMegaphone) {
                    return AccountStatusAlertName.megaphone
                }
                return null
            },
            cta: function () {
                // Adding the type informs the switch case that we have covered all cases for assertNever
                const alertName: AccountStatusAlertName | null = this.alertName
                switch (alertName) {
                    case AccountStatusAlertName.megaphone:
                        return this.currentMegaphone.ctaText
                    case AccountStatusAlertName.pendingClose:
                    case AccountStatusAlertName.pendingPayoffThenLimitedMode:
                        return i18n.t('components.accountStatusBanner.ctaViewDetails')
                    case AccountStatusAlertName.fraud:
                        return i18n.t('components.accountStatusBanner.fraud.cta')
                    case AccountStatusAlertName.blocked:
                        return i18n.t('components.accountStatusBanner.blocked.cta')
                    case AccountStatusAlertName.limitedMode:
                        return i18n.t('components.accountStatusBanner.limitedMode.cta')
                    case AccountStatusAlertName.homeReAttachInProgress:
                        return i18n.t('components.accountStatusBanner.homeReAttachInProgress.cta')
                    case AccountStatusAlertName.expiringFloodInsurance:
                        return i18n.t('components.accountStatusBanner.expiringFloodInsurance.cta')
                    case AccountStatusAlertName.expiredFloodInsurance:
                        return i18n.t('components.accountStatusBanner.expiredFloodInsurance.cta')
                    case AccountStatusAlertName.delinquent:
                        return i18n.t('components.accountStatusBanner.delinquent.cta')
                    case AccountStatusAlertName.overLimit:
                        return i18n.t('components.accountStatusBanner.overLimit.cta')
                    case AccountStatusAlertName.closed:
                    case AccountStatusAlertName.paymentProcessingAfterPastDue:
                    case AccountStatusAlertName.suspiciousPaymentFraud:
                        return null
                    case AccountStatusAlertName.homeReAttachInRescission:
                    case AccountStatusAlertName.pastDue:
                        return i18n.t('components.accountStatusBanner.ctaPayNow')
                    default:
                        throw assertNever(alertName, `Unexpected alert name: ${this.alertName}`)
                }
            },
            shouldShowButtonOnAccountStatusBanner() {
                return ![AvenAccountStanding.homeReAttachInRescission, AvenAccountStanding.paymentProcessingAfterPastDue].includes(this.accountStanding) && this.cta && !this.showPaymentFraudBanner
            },
            endRescissionStr() {
                return generic.convertDateTimeFormat(generic.addToGivenDate(this.rescissionInfo.endRescission, 1, 'day').toDate(), { inputTimezone: 'UTC', outputFormat: 'MMM DD' })
            },
            isDelinquent() {
                // As long as dq days is > 0, they are DQ, regardless of the account standing (they could be active before we block them)
                return this.daysDelinquent > 0
            },
            getDelinquentLabelKey() {
                if (!this.isDelinquent) {
                    return ''
                }

                if (this.canOfferLateFeeReversal) {
                    return '.withLateFeeReversalOffer'
                }

                if (this.accountStanding === AvenAccountStanding.blocked) {
                    return '.blocked'
                }

                return '.notBlocked'
            },
            expiringFloodInsurance() {
                return useOverviewStore().expiringFloodInsurance
            },
            limitedModeCreditLimit() {
                return useOverviewStore().limitedModeCreditLimit
            },
            existingHomeReAttach() {
                return useOverviewStore().existingHomeReAttach
            },
            accountStanding() {
                return useOverviewStore().avenAccountStanding
            },
            rescissionInfo() {
                return useOverviewStore().rescissionInfo
            },
            amountPastDue() {
                return useOverviewStore().amountPastDue
            },
            dqPaymentProcessEstimate() {
                const dqCuredAt = useOverviewStore().dqCuredAt

                if (!dqCuredAt) {
                    return ''
                }

                return dayjs(useOverviewStore().dqCuredAt).add(PAYMENT_PROCESSING_LAG_DAYS, 'day').format(DISPLAY_DATE_FORMAT)
            },
            daysDelinquent() {
                return useOverviewStore().daysDelinquent
            },
            currentMegaphone() {
                return useOverviewStore().currentMegaphone
            },
            isAccountChargedOff() {
                return useOverviewStore().isAccountChargedOff
            },
            showPaymentFraudBanner() {
                // Don't prematurely show the banner if we haven't blocked the account yet
                return useOverviewStore().showPaymentFraudBanner && this.accountStanding === AvenAccountStanding.blocked
            },
        },
        methods: {
            reportLostCall: function () {
                location.href = `tel:${contact_info.phone}`
            },
            btnOnClickHandler: async function () {
                this.$logEvent('click_button_status_banner_cta', {
                    alertName: this.alertName,
                    accountStanding: this.accountStanding,
                    isDelinquent: this.isDelinquent,
                    currentMegaphone: this.currentMegaphone,
                })
                // Adding the type informs the switch case that we have covered all cases for assertNever
                const alertName: AccountStatusAlertName | null = this.alertName
                switch (alertName) {
                    case AccountStatusAlertName.megaphone: {
                        const routeName = this.currentMegaphone.ctaRouteName
                        await this.$router.push({
                            name: routeName,
                        })
                        break
                    }
                    case AccountStatusAlertName.pendingClose:
                    case AccountStatusAlertName.pendingPayoffThenLimitedMode:
                        await this.$router.push({
                            name: RouteNames.CLOSE_ACCOUNT_PAYOFF,
                        })
                        break
                    case AccountStatusAlertName.fraud:
                    case AccountStatusAlertName.blocked:
                        this.reportLostCall()
                        break
                    case AccountStatusAlertName.limitedMode:
                        await this.$router.push({
                            name: RouteNames.RETENTION_HOME_REATTACH,
                        })
                        break
                    case AccountStatusAlertName.homeReAttachInProgress:
                        logger.info(`existingHomeReAttach: ${JSON.stringify(this.existingHomeReAttach)}`)
                        window.open(this.existingHomeReAttach.jodlLink, '_blank')
                        break
                    case AccountStatusAlertName.expiringFloodInsurance:
                    case AccountStatusAlertName.expiredFloodInsurance:
                        window.open(this.expiringFloodInsurance.documentUploadLink, '_blank')
                        break
                    case AccountStatusAlertName.delinquent:
                    case AccountStatusAlertName.pastDue:
                    case AccountStatusAlertName.overLimit:
                    case AccountStatusAlertName.homeReAttachInRescission:
                    case AccountStatusAlertName.paymentProcessingAfterPastDue:
                    case AccountStatusAlertName.suspiciousPaymentFraud: {
                        if (this.isDelinquent) {
                            this.$logEvent('click_button_dq_make_payment')

                            if (this.canOfferLateFeeReversal) {
                                await this.offerLateFeeReversal()
                            }
                        }

                        await this.$router.push({
                            name: RouteNames.MAKE_PAYMENT,
                            query: {
                                schedulePayment: true,
                            },
                        })
                        break
                    }
                    case AccountStatusAlertName.closed:
                        break
                    default:
                        throw assertNever(alertName, `Unexpected alert name: ${this.alertName}`)
                }
            },
            btnOnScheduleACall: function () {
                this.$logEvent('click_button_dq_banner_schedule_a_call')

                this.$router.push({
                    name: RouteNames.CALL_BOOKING,
                })
            },
            btnOnGetHelp: function () {
                this.$logEvent('click_button_dq_banner_get_help')

                this.$router.push({
                    name: RouteNames.GET_HELP_PAYMENT_OPTIONS,
                })
            },
            handleClose: async function () {
                this.$logEvent('click_button_status_banner_dismiss', {
                    alertName: this.alertName,
                    accountStanding: this.accountStanding,
                    isDelinquent: this.isDelinquent,
                    currentMegaphone: this.currentMegaphone,
                })
                await dismissMegaphone(this.currentMegaphone)
                useOverviewStore().patchOverviewAndCustomerInfo({ currentMegaphone: undefined })
            },
        },
        mounted: async function () {
            this.canOfferPastDueAcccountReage = await this.getCanOfferPastDueAcccountReage()
            this.canOfferLateFeeReversal = await this.getCanOfferLateFeeReversal()
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/banner';
    @import 'node_modules/aven_shared/src/styles/elements/button';

    .banner {
        max-width: 640px;
        margin: 0;
    }

    .banner-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .banner-close > img {
        width: 16px;
        height: 16px;
        vertical-align: top;
    }
</style>
