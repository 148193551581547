"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeOwnershipVerificationStatus = exports.HomeOwnershipStatus = void 0;
var HomeOwnershipStatus;
(function (HomeOwnershipStatus) {
    HomeOwnershipStatus["OWN"] = "OWN";
    HomeOwnershipStatus["RENT"] = "RENT";
})(HomeOwnershipStatus = exports.HomeOwnershipStatus || (exports.HomeOwnershipStatus = {}));
var HomeOwnershipVerificationStatus;
(function (HomeOwnershipVerificationStatus) {
    HomeOwnershipVerificationStatus["VERIFIED"] = "VERIFIED";
    HomeOwnershipVerificationStatus["POTENTIALLY_VERIFIED"] = "POTENTIALLY_VERIFIED";
    HomeOwnershipVerificationStatus["UNVERIFIED"] = "UNVERIFIED";
})(HomeOwnershipVerificationStatus = exports.HomeOwnershipVerificationStatus || (exports.HomeOwnershipVerificationStatus = {}));
