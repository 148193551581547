import { defineStore } from 'pinia'
import { appSessionStorage } from '@/utils/storage'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { logger } from '@/utils/logger'
import { RoutePaths } from '@/routes/router.types'
import { openReplay } from '@/utils/openReplay'
import { ActivateCardResponse } from 'aven_types'

enum SessionDataKeys {
    rootPath = 'rootPath',
}

export const useGlobalUiStore = () => {
    const globalUiStore = defineStore('globalUi', {
        state: () => {
            return {
                loading: false,
                error: '',
                errorPayload: Object.create(null) as ActivateCardResponse,
                // null === unknown, assumed no but can be updated. false === definitely no. true === definitely yes
                showLocationPromptOnAppStart: null as boolean | null,
                // browser mSite
                //  currentRootPath starts out as ACTIVITY
                //  Dashboard layout then mutates currentRootPath as user clicked on either activity, statement or card.
                // webView
                //  currentRootPath is set by native side via Splash.vue - loadRootPage
                //  currentRootPath is never changed for that webview as native app has 3 webviews each corresponding to one tab.
                currentRootPath: appSessionStorage.getItem(SessionDataKeys.rootPath) || '',
                deeplinkPath: '',
                hasShownCardArrivalCheck: false,
                hasShownMailerCodeArrivalCheck: false,
            }
        },
        getters: {
            currentRootPathOrDefault: (state) => state.currentRootPath || RoutePaths.ACTIVITY,
        },
        actions: {
            setCurrentRootPath(payload: string) {
                // when using single webview(iOS) update root is allowed
                // android still uses 3 webviews, one per tab
                // when not in webview, root path can be changed any time
                const deviceInfoStore = useDeviceInfoStore()
                const allowRootPathUpdate = deviceInfoStore.isSingleWebView || !deviceInfoStore.isWebView
                const isRootPathInitiated = !!appSessionStorage.getItem(SessionDataKeys.rootPath)
                if (allowRootPathUpdate || (!isRootPathInitiated && payload.length > 0)) {
                    logger.info(`vuex store before update, currentRootPath ${this.currentRootPath}, sessionStorage: ${appSessionStorage.getItem(SessionDataKeys.rootPath)}`)
                    appSessionStorage.setItem(SessionDataKeys.rootPath, payload)
                    this.currentRootPath = payload
                    logger.info(`vuex store after update, currentRootPath: ${this.currentRootPath}, sessionStorage: ${appSessionStorage.getItem(SessionDataKeys.rootPath)}`)
                }
            },
            updateDeeplinkPath(path: string): void {
                logger.info(`Updating deep link path to ${path}`)
                this.deeplinkPath = path
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(globalUiStore)
    return globalUiStore
}
