"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountStatusAlertName = void 0;
/**
 * Enum for account status alert names used in the AccountStatusBanner.vue component
 */
var AccountStatusAlertName;
(function (AccountStatusAlertName) {
    AccountStatusAlertName["delinquent"] = "delinquent";
    AccountStatusAlertName["pastDue"] = "pastDue";
    AccountStatusAlertName["overLimit"] = "overLimit";
    AccountStatusAlertName["fraud"] = "fraud";
    AccountStatusAlertName["blocked"] = "blocked";
    AccountStatusAlertName["closed"] = "closed";
    AccountStatusAlertName["pendingClose"] = "pendingClose";
    AccountStatusAlertName["pendingPayoffThenLimitedMode"] = "pendingPayoffThenLimitedMode";
    AccountStatusAlertName["limitedMode"] = "limitedMode";
    AccountStatusAlertName["homeReAttachInProgress"] = "homeReAttachInProgress";
    AccountStatusAlertName["homeReAttachInRescission"] = "homeReAttachInRescission";
    AccountStatusAlertName["expiringFloodInsurance"] = "expiringFloodInsurance";
    AccountStatusAlertName["expiredFloodInsurance"] = "expiredFloodInsurance";
    AccountStatusAlertName["paymentProcessingAfterPastDue"] = "paymentProcessingAfterPastDue";
    AccountStatusAlertName["megaphone"] = "megaphone";
    AccountStatusAlertName["suspiciousPaymentFraud"] = "suspiciousPaymentFraud";
})(AccountStatusAlertName = exports.AccountStatusAlertName || (exports.AccountStatusAlertName = {}));
