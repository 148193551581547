import { render, staticRenderFns } from "./BalanceDetailsUpgradeToHomeBeard.vue?vue&type=template&id=9c0e7444&scoped=true"
import script from "./BalanceDetailsUpgradeToHomeBeard.vue?vue&type=script&setup=true&lang=ts"
export * from "./BalanceDetailsUpgradeToHomeBeard.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BalanceDetailsUpgradeToHomeBeard.vue?vue&type=style&index=0&id=9c0e7444&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c0e7444",
  null
  
)

export default component.exports