"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsePlaidPurpose = exports.RequestLinkTokenError = void 0;
var RequestLinkTokenError;
(function (RequestLinkTokenError) {
    /**
     * Aven backend could not communicate w/ Plaid's service due to a socket hang up
     */
    RequestLinkTokenError["socketHangUp"] = "socketHangUp";
    /**
     * If the request to Plaid to retrieve a link token succeeds, but there is no token
     * provided in the response
     */
    RequestLinkTokenError["tokenNotProvided"] = "tokenNotProvided";
    /**
     * An error that we don't explicitly handle yet
     */
    RequestLinkTokenError["genericError"] = "genericError";
})(RequestLinkTokenError = exports.RequestLinkTokenError || (exports.RequestLinkTokenError = {}));
/** @TranslateToPython @TranslateToEnum */
var ParsePlaidPurpose;
(function (ParsePlaidPurpose) {
    ParsePlaidPurpose["INCOME"] = "INCOME";
    ParsePlaidPurpose["ADVISOR_LEAD_INCOME"] = "ADVISOR_LEAD_INCOME";
    ParsePlaidPurpose["ACCOUNT_DATA"] = "ACCOUNT_DATA";
    ParsePlaidPurpose["GET_TOP_MERCHANTS_BY_AMOUNT"] = "GET_TOP_MERCHANTS_BY_AMOUNT";
    ParsePlaidPurpose["GET_MOST_RECENT_FLOWS"] = "GET_MOST_RECENT_FLOWS";
    ParsePlaidPurpose["GET_BALANCES"] = "GET_BALANCES";
    ParsePlaidPurpose["GET_SUBSCRIPTIONS"] = "GET_SUBSCRIPTIONS";
    ParsePlaidPurpose["FIND_RECURRING_STREAM"] = "FIND_RECURRING_STREAM";
})(ParsePlaidPurpose = exports.ParsePlaidPurpose || (exports.ParsePlaidPurpose = {}));
