"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardActivationError = void 0;
var CardActivationError;
(function (CardActivationError) {
    CardActivationError["CARD_ALREADY_ACTIVATED"] = "CARD_ALREADY_ACTIVATED";
    CardActivationError["CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED"] = "CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED";
    CardActivationError["ERROR_CARD_ACTIVATION_DATA_INVALID"] = "ERROR_CARD_ACTIVATION_DATA_INVALID";
    CardActivationError["ERROR_CARD_ACTIVATION_CLOSED_CARD"] = "ERROR_CARD_ACTIVATION_CLOSED_CARD";
    CardActivationError["ERROR_CARD_ACTIVATION_OTHER_OWNER"] = "ERROR_CARD_ACTIVATION_OTHER_OWNER";
    CardActivationError["ERROR_CARD_ACTIVATION_OTHER_CARD"] = "ERROR_CARD_ACTIVATION_OTHER_CARD";
    CardActivationError["ERROR_IN_RESCISSION_PERIOD"] = "ERROR_IN_RESCISSION_PERIOD";
    CardActivationError["ERROR_PRIMARY_CARD_NOT_ACTIVATED"] = "ERROR_PRIMARY_CARD_NOT_ACTIVATED";
    CardActivationError["ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED"] = "ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED";
    CardActivationError["ERROR_BLOCKED_BY_TITLE_INSURANCE"] = "ERROR_BLOCKED_BY_TITLE_INSURANCE";
    CardActivationError["ERROR_BLOCKED_BY_HAZARD_INSURANCE"] = "ERROR_BLOCKED_BY_HAZARD_INSURANCE";
    CardActivationError["ERROR_BLOCKED_BY_EMPLOYMENT_VERIFICATION"] = "ERROR_BLOCKED_BY_EMPLOYMENT_VERIFICATION";
    CardActivationError["ERROR_BLOCKED_BY_MLS_LISTING"] = "ERROR_BLOCKED_BY_MLS_LISTING";
    CardActivationError["ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE"] = "ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE";
    CardActivationError["ERROR_BLOCKED_BY_PENDING_CONTINGENCIES"] = "ERROR_BLOCKED_BY_PENDING_CONTINGENCIES";
    CardActivationError["ERROR_BLOCKED_BY_UCC_INCOMPLETE_FLOW"] = "ERROR_BLOCKED_BY_UCC_INCOMPLETE_FLOW";
    CardActivationError["ERROR_BLOCKED_BY_UCC_IDENTITY_CHECKS"] = "ERROR_BLOCKED_BY_UCC_IDENTITY_CHECKS";
    CardActivationError["ERROR_BLOCKED_BY_NATURAL_DISASTER_VIDEO_AND_LOCATION_REQUIREMENT"] = "ERROR_BLOCKED_BY_NATURAL_DISASTER_VIDEO_AND_LOCATION_REQUIREMENT";
    CardActivationError["ERROR_GENERIC_FAILED_CARD_ACTIVATION"] = "ERROR_GENERIC_FAILED_CARD_ACTIVATION";
})(CardActivationError = exports.CardActivationError || (exports.CardActivationError = {}));
